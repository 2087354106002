import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { GMap } from 'primereact/gmap';
import Form from '@rjsf/material-ui';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import Settings from '../../../settings/settings';
import Query from '../../../query/query';
// import 'primereact/resources/themes/nova/theme.css';
// import 'primereact/resources/primereact.min.css';
// import 'primeicons/primeicons.css';
import OfferTemplateFunctions from './offertemplatefunctions';

const moment = require('moment');

class AppUserOffers extends Component {
  constructor(props) {
    super(props);
    this.parent = props.parent.parent;
    this.appState = this.parent.appState;
    // alert(this.appState.getUserOfferSchemaEndPoint);

    this.otf = new OfferTemplateFunctions();
    this.settings = new Settings();
    this.query = new Query();

    // datatable templates
    this.column1BodyTemplate = this.column1BodyTemplate.bind(this);
    this.column2BodyTemplate = this.column2BodyTemplate.bind(this);
    this.column3BodyTemplate = this.column3BodyTemplate.bind(this);
    this.column4BodyTemplate = this.column4BodyTemplate.bind(this);
    this.column5BodyTemplate = this.column5BodyTemplate.bind(this);

    // load
    this.loadUserOffers = this.loadUserOffers.bind(this);
    this.reloadOffers = this.reloadOffers.bind(this);
    this.loadUserCompanies = this.loadUserCompanies.bind(this);

    this.hideHiddenFields = this.hideHiddenFields.bind(this);

    // add
    this.handleShowAddUserOffersForm = this.handleShowAddUserOffersForm.bind(this);
    this.handleHideAddUserOffersForm = this.handleHideAddUserOffersForm.bind(this);
    this.handleAddUserOfferFormSubmit = this.handleAddUserOfferFormSubmit.bind(this);

    // update
    this.handleHideUpdateUserOfferForm = this.handleHideUpdateUserOfferForm.bind(
      this,
    );
    this.handleShowUpdateUserOfferForm = this.handleShowUpdateUserOfferForm.bind(
      this,
    );
    this.handleUpdateUserOfferFormSubmit = this.handleUpdateUserOfferFormSubmit.bind(
      this,
    );

    this.setUpdateOfferformData = this.setUpdateOfferformData.bind(this);
    this.handleDeleteUserOffer = this.handleDeleteUserOffer.bind(this);

    this.loadUserOfferFormsSchema = this.loadUserOfferFormsSchema.bind(this);

    // this.loadUserOffers();

    const addOfferFormSchema = {
      type: 'object',
      required: ['name'],
    };

    const updateOfferFormSchema = {
      type: 'object',
      required: ['name'],
    };

    const uiSchema = {
    };

    this.state = {
      userOfferColumns: [],
      userOfferRows: [],
      addOfferFormSchema,
      updateOfferFormSchema,
      updateFieldsToIgnore: ['active'],
      addFieldsToIgnore: ['id', 'active'],
      addOfferformData: {},
      updateOfferformData: {},
      uiSchema,
      companySelectValue: null,
    };

    // this.loadUserOffers();
    // this.loadUserOfferFormsSchema();
    // this.loadUserCompanies();
  }

  componentDidMount() {
    this.loadUserOffers();
    this.loadUserOfferFormsSchema();
    this.loadUserCompanies();
  }

  handleAddUserOfferFormSubmit(data, event) {
    event.preventDefault();

    const body = JSON.stringify({});
    // console.log(`this.appState.getAddUserOfferEndPoint(data.formData):
    // ${this.appState.getAddUserOfferEndPoint(data.formData)}`);
    // fetch(this.appState.getAddUserOfferEndPoint(data.formData),

    const addUserOfferEndPoint = this.query.getAddUserOfferEndPoint(data.formData);
    console.log(`addUserOfferEndPoint: ${addUserOfferEndPoint}`);
    fetch(addUserOfferEndPoint,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `client ${this.appState.getToken()}`,
        },
        body,
      })
      .then((response) => {
        if (response.ok) {
          // console.log('response.ok');
          response.json().then(
            (json) => {
              document.getElementById('addOfferResult').innerHTML = JSON.stringify(json);
              document.getElementById('hideAddUserOffersFormButton').click();
              this.reloadOffers();
            },
          );
        } else {
          // console.log('not response.ok');
        }
      });
    this.loadUserOfferFormsSchema();
    this.loadUserOffers();
    this.setState({});
  }

  handleShowAddUserOffersForm(e) {
    e.preventDefault();

    window.scrollTo(0, 0);

    const {
      addOfferFormSchema,
      addOfferformData,
      uiSchema,
      companyList,
      companySelectValue, // eslint-disable-line no-unused-vars
    } = this.state;

    const { properties } = addOfferFormSchema;

    if (properties === null || properties === undefined || !(Object.keys(properties).length)) {
      return;
    }

    const {
      latitude,
      longitude,
    } = properties;

    const mapOptions = {
      center: {
        lat: parseFloat(latitude.default),
        lng: parseFloat(longitude.default),
      },
      zoom: 3,
      type: 'terrain',
      mapTypeControl: false,
      zoomControl: false,
      streetViewControl: false,
    };

    const mapOverlays = [
      new google.maps.Marker({ // eslint-disable-line no-undef
        position: {
          lat: parseFloat(latitude.default),
          lng: parseFloat(longitude.default),
        },
        title: 'Location',
        draggable: true,
      }),
    ];

    // alert(JSON.stringify(companySelectValue));

    const element = (
      <div>
        <Button
          id="hideAddUserOffersFormButton"
          variant="contained"
          onClick={(event) => { this.handleHideAddUserOffersForm(event); }}
        >
          Hide Form
        </Button>
        <br />
        <Form
          formData={addOfferformData}
          onSubmit={(data, event) => {
            const { formData } = data;
            formData.latitude = document.getElementById('root_latitude').value;
            formData.longitude = document.getElementById('root_longitude').value;
            this.handleAddUserOfferFormSubmit(data, event);
          }}
          schema={addOfferFormSchema}
          uiSchema={uiSchema}
        >
          <Dropdown
            value={this.state.companySelectValue} // eslint-disable-line react/destructuring-assignment, max-len
            options={companyList}
            filter
            showClear
            filterBy="name"
            placeholder="Company"
            optionLabel="name"
            style={{ width: '100%' }}
            onChange={(event) => {
              const { value } = event;
              if (value) {
                document.getElementById('root_company').value = value.id;
                // alert(JSON.stringify(value));
                this.setState({
                  companySelectValue: value,
                });
                // ReactDOM.render(element, document.getElementById('addOfferForm'));
              }
            }}
          />
          <br />
          <br />
          <strong>
            Drag Marker to Location
          </strong>
          <br />
          <br />
          <GMap
            options={mapOptions}
            overlays={mapOverlays}
            onOverlayDragEnd={(event) => {
              // console.log(event.originalEvent.latLng.lat());
              // console.log(event.originalEvent.latLng.lng());
              document.getElementById('root_latitude').value = event.originalEvent.latLng.lat();
              document.getElementById('root_longitude').value = event.originalEvent.latLng.lng();
            }}
            style={{ width: '100%', minHeight: '50vh' }}
          />
          <br />
          <Button
            label="Add"
            type="submit"
            className="pi pi-plus"
            rel="noreferrer"
          />
        </Form>
        <div id="addOfferResult" />
        <br />
      </div>
    );
    // document.getElementById('hideUpdateUserOffersFormButton').click();
    document.getElementById('handleShowAddUserOffersFormButton').style.visibility = 'hidden';
    ReactDOM.render(element, document.getElementById('addOfferForm'));
    this.setState({});
    this.hideHiddenFields();
  }

  handleHideAddUserOffersForm(e) {
    e.preventDefault();
    const element = (<div />);
    ReactDOM.render(element, document.getElementById('addOfferForm'));
    document.getElementById('handleShowAddUserOffersFormButton').style.visibility = 'visible';
    this.setState({});
  }

  handleUpdateUserOfferFormSubmit(data, event) {
    event.preventDefault();

    const {
      endPoint,
      query,
      operationName,
      variables,
    } = this.query.getUpdateUserOfferData(data.formData);

    const body = JSON.stringify({
      query,
      operationName,
      variables,
    });

    this.appState.logify(
      'endPoint',
      endPoint,
    );
    fetch(endPoint,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `client ${this.appState.getToken()}`,
        },
        body,
      })
      .then((response) => {
        if (response.ok) {
          // console.log('response.ok');
          response.json().then(
            (json) => {
              document.getElementById('hiddenResult').innerHTML = JSON.stringify(json);
              document.getElementById('hideUpdateUserOffersFormButton').click();
              this.reloadOffers();
            },
          );
        } else {
          // console.log('not response.ok');
        }
      });
    this.loadUserOfferFormsSchema();
    this.loadUserOffers();
    this.setState({});
  }

  handleShowUpdateUserOfferForm(e, id = 0) {
    e.preventDefault();
    if (id === 0) {
      return;
    }

    window.scrollTo(0, 0);

    // document.getElementById('hideAddUserOffersFormButton').click();

    this.setUpdateOfferformData(id);

    const {
      updateOfferFormSchema,
      updateOfferformData,
      uiSchema,
      companySelectValue,
      companyList,
    } = this.state;

    // alert(JSON.stringify(updateOfferformData));
    const {
      latitude,
      longitude,
    } = updateOfferformData;

    const mapOptions = {
      center: {
        lat: parseFloat(latitude),
        lng: parseFloat(longitude),
      },
      zoom: 3,
      type: 'terrain',
      mapTypeControl: false,
      zoomControl: false,
      streetViewControl: false,
    };

    const mapOverlays = [
      new google.maps.Marker({ // eslint-disable-line no-undef
        position: {
          lat: parseFloat(latitude),
          lng: parseFloat(longitude),
        },
        title: 'Location',
        draggable: true,
      }),
    ];

    const element = (
      <div>
        <Button
          id="hideUpdateUserOffersFormButton"
          variant="contained"
          onClick={(event) => { this.handleHideUpdateUserOfferForm(event); }}
        >
          Hide Form
        </Button>
        <br />
        <Form
          formData={updateOfferformData}
          onSubmit={(data, event) => {
            const { formData } = data;
            formData.latitude = document.getElementById('root_latitude').value;
            formData.longitude = document.getElementById('root_longitude').value;
            this.handleUpdateUserOfferFormSubmit(data, event);
          }}
          schema={updateOfferFormSchema}
          uiSchema={uiSchema}
        >
          <Dropdown
            value={companySelectValue}
            options={companyList}
            filter
            showClear
            filterBy="name"
            placeholder="Company"
            optionLabel="name"
            style={{ width: '100%' }}
            onChange={(event) => {
              const { value } = event;
              if (value) {
                document.getElementById('root_company').value = value.id;
                this.setState({ companySelectValue: value });
              }
            }}
          />
          <br />
          <br />
          <strong>
            Drag Marker to Location
          </strong>
          <br />
          <GMap
            options={mapOptions}
            overlays={mapOverlays}
            onOverlayDragEnd={(event) => {
              // console.log(event.originalEvent.latLng.lat());
              // console.log(event.originalEvent.latLng.lng());
              document.getElementById('root_latitude').value = event.originalEvent.latLng.lat();
              document.getElementById('root_longitude').value = event.originalEvent.latLng.lng();
            }}
            style={{ width: '100%', minHeight: '50vh' }}
          />
          <br />
          <Button label="Update" type="submit" icon="pi" rel="noreferrer" />
        </Form>
        <br />
      </div>
    );
    // document.getElementById('hideAddUserOffersFormButton').click();
    ReactDOM.render(element, document.getElementById('updateOfferForm'));
    this.setState({});
  }

  handleHideUpdateUserOfferForm(e) {
    e.preventDefault();
    const element = (<div />);
    ReactDOM.render(element, document.getElementById('updateOfferForm'));
    this.setState({});
  }

  handleDeleteUserOffer(e, id = 0) {
    e.preventDefault();

    const body = JSON.stringify({});

    // console.log(`this.appState.getDeleteUserOfferEndPoint(id):
    // ${this.appState.getDeleteUserOfferEndPoint(id)}`);
    // fetch(this.appState.getDeleteUserOfferEndPoint(id),

    const deleteUserOfferEndPoint = this.query.getDeleteUserOfferEndPoint(id);
    console.log(`deleteUserOfferEndPoint: ${deleteUserOfferEndPoint}`);
    fetch(deleteUserOfferEndPoint,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `client ${this.appState.getToken()}`,
        },
        body,
      })
      .then((response) => {
        if (response.ok) {
          // console.log('response.ok');
          response.json().then(
            (json) => {
              document.getElementById('deleteOfferResult').innerHTML = JSON.stringify(json);
            },
          );
        } else {
          // console.log('not response.ok');
        }
      });
    this.loadUserOfferFormsSchema();
    this.loadUserOffers();
    this.setState({});
  }

  setUpdateOfferformData(id = 0) {
    if (id === 0) {
      return;
    }

    const {
      userOfferRows,
      updateOfferFormSchema,
    } = this.state;

    const {
      userId,
    } = this.appState;

    const updateOfferformData = {};
    const { properties } = updateOfferFormSchema;
    const propertiesKeys = Object.keys(properties);

    // alert(`updateOfferFormSchema: ${JSON.stringify(updateOfferFormSchema.properties)}`);

    userOfferRows.forEach((row) => {
      if (row.id === id) {
        propertiesKeys.forEach((column) => {
          switch (column) {
            case 'actions':
              break;
            case 'id':
              // alert(row[column]);
              updateOfferformData[column] = parseInt(row[column], 10);
              break;
            case 'owner':
              updateOfferformData[column] = parseInt(userId, 10);
              break;
            case 'company':
              updateOfferformData[column] = parseInt(userId, 10);
              break;
            case 'last_modified':
              updateOfferformData[column] = moment(row[column], 'x').format('YYYY-MM-DD hh:mm:ss');
              break;
            case 'expiration_date':
              updateOfferformData[column] = moment(row[column], 'x').format('YYYY-MM-DD hh:mm:ss');
              break;
            case 'initial_offer_date':
              updateOfferformData[column] = moment(row[column], 'x').format('YYYY-MM-DD hh:mm:ss');
              break;
            default:
              updateOfferformData[column] = row[column];
          }
        });
      }
    });
    // alert(`updateOfferformData" ${JSON.stringify(updateOfferformData)}`);
    this.state.updateOfferformData = updateOfferformData;
  }

  hideHiddenFields() { // eslint-disable-line class-methods-use-this
    // for material ui it leaves alot of space for hidden elements.
    // You have to traverse the dom up 3 parent levels to hide the correct div
    // document.getElementById('root_field_name').parentElement.parentElement.parentElement
    const inputs = document.getElementsByTagName('input');
    for (let i = 0; i < inputs.length; i += 1) {
      const input = inputs[i];
      if (input.type.toLowerCase() === 'hidden') {
        const parent = input.parentElement;
        if (parent) {
          const grandParent = parent.parentElement;
          grandParent.style.display = 'none';
        }
      }
    }
  }

  loadUserOfferFormsSchema() {
    const {
      uiSchema,
      addFieldsToIgnore,
      updateFieldsToIgnore,
      addOfferFormSchema,
      updateOfferFormSchema,
    } = this.state;

    const {
      userId,
    } = this.appState;

    addOfferFormSchema.properties = {};
    updateOfferFormSchema.properties = {};

    // console.log(`this.appState.getUserOfferSchemaEndPoint():
    // ${this.appState.getUserOfferSchemaEndPoint()}`);
    const body = JSON.stringify({});

    // alert('1');
    // if (!this.appState.getUserOfferSchemaEndPoint) {
    //  return;
    // }
    // alert('2');
    // console.log(`getUserOfferSchemaEndPoint: ${this.appState.getUserOfferSchemaEndPoint()}`);
    // alert(this.appState);
    // alert(JSON.stringify(this.appState));

    // const userOfferSchemaEndPoint = this.appState.getUserOfferSchemaEndPoint();
    // console.log(`userOfferSchemaEndPoint ${userOfferSchemaEndPoint}`);
    // fetch(userOfferSchemaEndPoint,

    const userOfferSchemaEndPoint = this.query.getUserOfferSchemaEndPoint();
    console.log(`userOfferSchemaEndPoint: ${userOfferSchemaEndPoint}`);
    fetch(userOfferSchemaEndPoint,

      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `client ${this.appState.getToken()}`,
        },
        body,
      })
      .then((response) => {
        if (response.ok) {
          response.json().then(
            (json) => {
              const { data } = json;
              const { __type } = data; // eslint-disable-line no-underscore-dangle
              const { fields } = __type;
              const properties = {};

              fields.forEach((field) => {
                const values = {};
                switch (field.type.name) {
                  case 'String':
                    values.title = field.name;
                    values.type = 'string';
                    values.maxLength = 250;
                    break;
                  case 'ID':
                    values.title = field.name;
                    values.type = 'integer';
                    break;
                  case 'Int':
                    values.title = field.name;
                    values.type = 'integer';
                    break;
                  case 'Float':
                    values.title = field.name;
                    values.type = 'number';
                    break;
                  case 'Boolean':
                    values.title = field.name;
                    values.type = 'boolean';
                    values.default = false;
                    values.enum = [true, false];
                    values.enumNames = ['true', 'false'];
                    // values.default = 'false';
                    // values.enum = ['true', 'false'];
                    // values.enumNames = ['true', 'false'];
                    break;
                  default:
                    values.title = field.name;
                    values.type = 'string';
                    values.maxLength = 250;
                    // values.minLength = 1;
                    // values.format = 'date-time'
                    // pattern: '^[a-z0-9-]+$'
                }

                switch (field.name) {
                  case 'id':
                    values.title = field.name;
                    values.type = 'integer';
                    values.readOnly = true;
                    uiSchema[field.name] = {
                      'ui:widget': 'hidden',
                    };
                    break;
                  case 'owner':
                    values.title = field.name;
                    values.type = 'integer';
                    values.readOnly = true;
                    values.default = parseInt(userId, 10); // this.appState.userId
                    uiSchema[field.name] = {
                      'ui:widget': 'hidden',
                    };
                    break;
                  case 'company':
                    values.title = field.name;
                    values.type = 'integer';
                    values.readOnly = true;
                    values.default = parseInt(0, 10); // this.appState.userId
                    uiSchema[field.name] = {
                      'ui:widget': 'hidden',
                    };
                    break;
                  case 'name':
                    values.title = field.name;
                    values.default = 'New Offer';
                    break;
                  case 'service_type':
                    values.title = field.name;
                    values.default = 'vps';
                    values.enum = ['vps', 'shared', 'dedicated', 'cloud', 'hybrid'];
                    values.enumNames = ['vps', 'shared', 'dedicated', 'cloud', 'hybrid'];
                    break;
                  case 'virtualization_type':
                    values.title = field.name;
                    values.default = 'kvm';
                    values.enum = ['bare_metal', 'xen', 'kvm', 'lxc', 'openvz', 'hyperv', 'other', 'vmware'];
                    values.enumNames = ['bare_metal', 'xen', 'kvm', 'lxc', 'openvz', 'hyperv', 'other', 'vmware'];
                    break;
                  case 'ram_type':
                    values.title = field.name;
                    values.default = 'ddr3';
                    values.enum = ['ddr2', 'ddr3', 'ddr4', 'ddr5'];
                    values.enumNames = ['ddr2', 'ddr3', 'ddr4', 'ddr5'];
                    break;
                  case 'ram_error_correction':
                    values.title = field.name;
                    values.default = 'no_ecc';
                    values.enum = ['no_ecc', 'ecc', 'ecc_registered'];
                    values.enumNames = ['no_ecc', 'ecc', 'ecc_registered'];
                    break;
                  case 'storage_type':
                    values.title = field.name;
                    values.default = 'ssd';
                    values.enum = ['hdd', 'ssd', 'nvme', 'hybrid_ssd_hdd'];
                    values.enumNames = ['hdd', 'ssd', 'nvme', 'hybrid_ssd_hdd'];
                    break;
                  case 'cpu_cores_type':
                    values.title = field.name;
                    values.default = 'virtual';
                    values.enum = ['virtual', 'dedicated'];
                    values.enumNames = ['virtual', 'dedicated'];
                    break;
                  case 'external_network_port_speed':
                    values.title = field.name;
                    values.default = 'fast_ethernet_100_mbps';
                    values.enum = ['fast_ethernet_100_mbps', 'gigabit_ethernet_1000_mbps', '10_gigabit_ethernet_10000_mbps'];
                    values.enumNames = ['fast_ethernet_100_mbps', 'gigabit_ethernet_1000_mbps', '10_gigabit_ethernet_10000_mbps'];
                    break;
                  case 'payment_methods':
                    values.title = field.name;
                    values.type = 'string';
                    values.default = 'credit_card';
                    values.enum = ['credit_card', 'paypal', 'ali_pay', 'crypto', 'wire_transfer', 'sepa', 'skrill', 'sms', 'unionpay', 'webmoney', 'western_union'];
                    values.enumNames = ['credit_card', 'paypal', 'ali_pay', 'crypto', 'wire_transfer', 'sepa', 'skrill', 'sms', 'unionpay', 'webmoney', 'western_union'];
                    break;
                  case 'aup':
                    values.title = field.name;
                    values.type = 'string';
                    values.default = 'none';
                    values.enum = ['none', 'tor', 'torrents', 'adult', 'mining', 'irc', 'vpn'];
                    values.enumNames = ['none', 'tor', 'torrents', 'adult', 'mining', 'irc', 'vpn'];
                    break;
                  case 'control_panel_type':
                    values.title = field.name;
                    values.default = 'no_panel';
                    values.enum = ['cpanel', 'directadmin', 'plesk', 'other', 'no_panel'];
                    values.enumNames = ['cpanel', 'directadmin', 'plesk', 'other', 'no_panel'];
                    break;
                  case 'support_method':
                    values.title = field.name;
                    values.default = 'email';
                    values.enum = ['', 'email', 'chat', 'phone', 'forum'];
                    values.enumNames = ['', 'email', 'chat', 'phone', 'forum'];
                    break;
                  case 'support_response_time':
                    values.title = field.name;
                    values.default = 'within_a_week';
                    values.enum = ['few_minutes', 'few_hours', 'within_a_day', 'within_a_week', 'more_than_a_week', 'same_month', 'more_than_a_month', 'other_unspecified'];
                    values.enumNames = ['few_minutes', 'few_hours', 'within_a_day', 'within_a_week', 'more_than_a_week', 'same_month', 'more_than_a_month', 'other_unspecified'];
                    break;
                  case 'management_type':
                    values.title = field.name;
                    values.default = 'managed';
                    values.enum = ['managed', 'self_managed'];
                    values.enumNames = ['managed', 'self_managed'];
                    break;
                  case 'setup_time':
                    values.title = field.name;
                    values.default = 'instant';
                    values.enum = ['instant', 'few_minutes', 'few_hours', 'within_a_day', 'more_than_a_day'];
                    values.enumNames = ['instant', 'few_minutes', 'few_hours', 'within_a_day', 'more_than_a_day'];
                    break;
                  case 'offer_rating':
                    values.title = field.name;
                    values.default = 'unrated';
                    values.enum = ['unrated', 'one', 'two', 'three', 'four', 'five'];
                    values.enumNames = ['unrated', 'one', 'two', 'three', 'four', 'five'];
                    break;
                  case 'visibility':
                    values.title = field.name;
                    values.type = 'string';
                    values.default = 'public';
                    values.enum = ['public', 'private', 'need_login'];
                    values.enumNames = ['public', 'private', 'need_login'];
                    break;
                  case 'yearly_cost':
                    values.title = field.name;
                    values.type = 'string';
                    values.default = '12';
                    break;
                  case 'ipv6_addresses':
                    values.title = field.name;
                    values.type = 'string';
                    values.default = 'none';
                    break;
                  case 'latitude':
                    values.title = field.name;
                    values.type = 'string';
                    values.readOnly = true;
                    values.default = '8.068021';
                    uiSchema[field.name] = {
                      'ui:widget': 'hidden',
                    };
                    break;
                  case 'longitude':
                    values.title = field.name;
                    values.type = 'string';
                    values.readOnly = true;
                    values.default = '12.5447264';
                    uiSchema[field.name] = {
                      'ui:widget': 'hidden',
                    };
                    break;
                  case 'ram_amount':
                    values.title = field.name;
                    values.type = 'string';
                    values.default = '1';
                    break;
                  case 'monthly_bandwidth':
                    values.title = field.name;
                    values.type = 'string';
                    values.default = '1';
                    break;
                  case 'ipv4_addresses':
                    values.title = field.name;
                    values.type = 'string';
                    values.default = '1';
                    break;
                  case 'shortcode':
                    values.title = field.name;
                    values.type = 'string';
                    values.readOnly = true;
                    values.default = 'auto generated';
                    uiSchema[field.name] = {
                      'ui:widget': 'hidden',
                    };
                    break;
                  case 'setup_fee':
                    values.title = field.name;
                    values.type = 'string';
                    values.default = '0';
                    break;
                  case 'last_modified':
                    values.title = field.name;
                    values.default = moment().format('YYYY-MM-DD hh:mm:ss');
                    values.readOnly = true;
                    uiSchema[field.name] = {
                      'ui:widget': 'hidden',
                    };
                    break;
                  case 'expiration_date':
                    values.title = field.name;
                    values.default = moment().format('YYYY-MM-DD hh:mm:ss');
                    break;
                  case 'initial_offer_date':
                    values.title = this.otf.formatFieldName(field.name);
                    values.default = moment().format('YYYY-MM-DD hh:mm:ss');
                    uiSchema[field.name] = {
                      // classNames: 'displayNone',
                      // inputType: 'hidden',
                      // 'ui:title': 'Your password',
                      'ui:widget': 'hidden',
                      // 'ui:title': '',
                      // 'ui:widget': 'password',
                      // 'ui:widget': 'alt-datetime',
                      // 'ui:widget': 'textarea',
                    };
                    break;
                  case 'location_iso_3166_alpha_2_country_code':
                    values.title = field.name;
                    values.default = 'US';
                    values.enum = [
                      'AF', 'AX', 'AL', 'DZ', 'AS', 'AD', 'AO', 'AI', 'AQ', 'AG', 'AR', 'AM', 'AW', 'AU', 'AT', 'AZ', 'BH', 'BS',
                      'BD', 'BB', 'BY', 'BE', 'BZ', 'BJ', 'BM', 'BT', 'BO', 'BQ', 'BA', 'BW', 'BV', 'BR', 'IO', 'BN', 'BG', 'BF',
                      'BI', 'KH', 'CM', 'CA', 'CV', 'KY', 'CF', 'TD', 'CL', 'CN', 'CX', 'CC', 'CO', 'KM', 'CG', 'CD', 'CK', 'CR',
                      'CI', 'HR', 'CU', 'CW', 'CY', 'CZ', 'DK', 'DJ', 'DM', 'DO', 'EC', 'EG', 'SV', 'GQ', 'ER', 'EE', 'ET', 'FK',
                      'FO', 'FJ', 'FI', 'FR', 'GF', 'PF', 'TF', 'GA', 'GM', 'GE', 'DE', 'GH', 'GI', 'GR', 'GL', 'GD', 'GP', 'GU',
                      'GT', 'GG', 'GN', 'GW', 'GY', 'HT', 'HM', 'VA', 'HN', 'HK', 'HU', 'IS', 'IN', 'ID', 'IR', 'IQ', 'IE', 'IM',
                      'IL', 'IT', 'JM', 'JP', 'JE', 'JO', 'KZ', 'KE', 'KI', 'KP', 'KR', 'KW', 'KG', 'LA', 'LV', 'LB', 'LS', 'LR',
                      'LY', 'LI', 'LT', 'LU', 'MO', 'MK', 'MG', 'MW', 'MY', 'MV', 'ML', 'MT', 'MH', 'MQ', 'MR', 'MU', 'YT', 'MX',
                      'FM', 'MD', 'MC', 'MN', 'ME', 'MS', 'MA', 'MZ', 'MM', 'NA', 'NR', 'NP', 'NL', 'NC', 'NZ', 'NI', 'NE', 'NG',
                      'NU', 'NF', 'MP', 'NO', 'OM', 'PK', 'PW', 'PS', 'PA', 'PG', 'PY', 'PE', 'PH', 'PN', 'PL', 'PT', 'PR', 'QA',
                      'RE', 'RO', 'RU', 'RW', 'BL', 'SH', 'KN', 'LC', 'MF', 'PM', 'VC', 'WS', 'SM', 'ST', 'SA', 'SN', 'RS', 'SC',
                      'SL', 'SG', 'SX', 'SK', 'SI', 'SB', 'SO', 'ZA', 'GS', 'SS', 'ES', 'LK', 'SD', 'SR', 'SJ', 'SZ', 'SE', 'CH',
                      'SY', 'TW', 'TJ', 'TZ', 'TH', 'TL', 'TG', 'TK', 'TO', 'TT', 'TN', 'TR', 'TM', 'TC', 'TV', 'UG', 'UA', 'AE',
                      'GB', 'US', 'UM', 'UY', 'UZ', 'VU', 'VE', 'VN', 'VG', 'VI', 'WF', 'EH', 'YE', 'ZM', 'ZW',
                    ];
                    break;
                  default:
                }

                properties[field.name] = values;

                if (!addFieldsToIgnore.includes(field.name)) {
                  addOfferFormSchema.properties[field.name] = values;
                }

                if (!updateFieldsToIgnore.includes(field.name)) {
                  updateOfferFormSchema.properties[field.name] = values;
                }
              });

              // const { offerSchema } = this.state;
              // offerSchema.properties = properties;

              // alert(`addOfferFormSchema:
              // ${JSON.stringify(addOfferFormSchema.properties)}`);
              // alert(`updateOfferFormSchema:
              // ${JSON.stringify(updateOfferFormSchema.properties)}`);
              // document.getElementById('offerSchemaResult').innerHTML = JSON.stringify(fields);

              this.setState({
                uiSchema,
                addOfferFormSchema,
                updateOfferFormSchema,
              });
              // alert(JSON.stringify(uiSchema));
            },
          );
        } else {
          // console.log('not response.ok');
        }
      });
  }

  loadUserCompanies() {
    const {
      userId,
    } = this.appState;

    const body = JSON.stringify({});
    // fetch(this.appState.getUserCompanyEndPoint(userId),

    const userCompanyEndPoint = this.query.getUserCompanyEndPoint(userId);
    console.log(`userCompanyEndPoint: ${userCompanyEndPoint}`);
    fetch(userCompanyEndPoint,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `client ${this.appState.getToken()}`,
        },
        body,
      })
      .then((response) => {
        if (response.ok) {
          // console.log('response.ok');
          response.json().then(
            (json) => {
              const companyList = json.data.companies;

              let companySelectValue = null;
              if (companyList.length > 0) {
                const company = companyList[0];
                companySelectValue = company; // eslint-disable-line prefer-destructuring
              }

              this.setState({
                companyList,
                companySelectValue,
              });
            },
          );
        } else {
          // console.log('not response.ok');
        }
      });
  }

  loadUserOffers() {
    const {
      userId,
    } = this.appState;

    const body = JSON.stringify({});
    // console.log(`fetch: ${this.appState.getUserOfferEndPoint(userId)}`);
    // fetch(this.appState.getUserOfferEndPoint(userId),

    const userOfferEndPoint = this.query.getUserOfferEndPoint(userId);
    console.log(`userOfferEndPoint: ${userOfferEndPoint}`);
    fetch(userOfferEndPoint,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `client ${this.appState.getToken()}`,
        },
        body,
      })
      .then((response) => {
        if (response.ok) {
          // console.log('response.ok');
          response.json().then(
            (json) => {
              if (json.data.offers) {
                // let index = 0;
                json.data.offers.forEach((offerRow) => {
                  // offerRow.id = index; // eslint-disable-line no-param-reassign
                  offerRow.actions = ( // eslint-disable-line no-param-reassign
                    <div>
                      <Button
                        className="pi pi-pencil"
                        onClick={(event) => {
                          this.handleShowUpdateUserOfferForm(event, offerRow.id);
                        }}
                      />
                      <br />
                      <br />
                      <Button
                        className="pi pi-trash"
                        onClick={(event) => { this.handleDeleteUserOffer(event, offerRow.id); }}
                      />
                    </div>
                  ); // eslint-disable-line no-param-reassign
                  // index += 1;
                });

                const offerJson = json.data.offers;

                const userOfferColumns = [];
                const firstRow = offerJson[0];
                if (Array.isArray(firstRow)) {
                  const columns = Object.keys(firstRow);
                  columns.forEach((column) => {
                    // alert(`column: ${JSON.stringify(column)}`);
                    // if (fieldsToIgnore.includes(column)) {
                    //   return;
                    // }

                    userOfferColumns.push({
                      field: column,
                      header: column,
                      key: column,
                      name: column,
                      filterable: true,
                      sortable: true,
                      editable: true,
                      autoLayout: true,
                    });
                  });
                }

                const userOfferRows = json.data.offers;

                // alert(`userOfferRows: ${JSON.stringify(userOfferRows)}`);

                this.setState({ userOfferColumns, userOfferRows });
              }
            },
          );
        } else {
          // console.log('not response.ok');
        }
      });
  }

  reloadOffers() {
    this.loadUserOffers();
  }

  column1BodyTemplate(rowData) { // eslint-disable-line class-methods-use-this
    const shortcodeUrl = `${this.appState.getBaseUrl()}o/${rowData.shortcode}`;
    let orderLink = (rowData.order_link) ? rowData.order_link : '#';
    orderLink = (orderLink.indexOf('://') === -1) ? `https://${orderLink}` : orderLink;
    return (
      <>
        <a href={shortcodeUrl} target="_top">{`${rowData.name}`}</a>
        -
        {` ${rowData.service_type} `}
        <br />
        {`${rowData.cpu_cores_amount} ${rowData.cpu_cores_type} Core(s) ${rowData.cpu_type}`}
        <br />
        {`${rowData.ram_amount} ${rowData.ram_type}`}
        <br />
        {`${this.otf.formatTotalStorageCapacity(rowData.total_storage_capacity)} ${rowData.storage_type}`}
        <br />
        {`Bandwidth: ${this.otf.formatMonthlyBandwidth(rowData.monthly_bandwidth)} /m`}
        <br />
        {`${rowData.location_iso_3166_alpha_2_country_code} ${rowData.location}`}
        <br />
        {this.otf.formatOfferRating(rowData.offer_rating)}
        <br />
        <a href={orderLink} target="_blank" rel="noreferrer">Order Now</a>
        <br />
      </>
    );
  }

  column2BodyTemplate(rowData) { // eslint-disable-line class-methods-use-this
    return (
      <>
        {`${rowData.cpu_cores_amount} ${rowData.cpu_cores_type} Core(s) ${rowData.cpu_type}`}
        <br />
      </>
    );
  }

  column3BodyTemplate(rowData) { // eslint-disable-line class-methods-use-this
    return (
      <>
        {`${rowData.ram_amount} ${rowData.ram_type}`}
        <br />
      </>
    );
  }

  column4BodyTemplate(rowData) { // eslint-disable-line class-methods-use-this
    return (
      <>
        {`${this.otf.formatTotalStorageCapacity(rowData.total_storage_capacity)} ${rowData.storage_type}`}
        <br />
      </>
    );
  }

  column5BodyTemplate(rowData) { // eslint-disable-line class-methods-use-this
    const secondYearCost = (rowData.second_year_cost) ? `Second Year: ${this.otf.formatCost(rowData.second_year_cost)}` : '';
    return (
      <>
        {`${this.otf.formatCost(rowData.yearly_cost)} /y`}
        <br />
        {secondYearCost}
        <br />
      </>
    );
  }

  render() {
    const {
      userOfferRows,
      userOfferColumns,
    } = this.state;

    const dynamicColumns = userOfferColumns.map( // eslint-disable-line no-unused-vars
      (col) => {
        let template = null;
        let columnStyle = { width: '3em' };
        switch (col.field) {
          case 'founded':
            template = this.foundedBodyTemplate;
            break;
          case 'id':
            // template = this.foundedBodyTemplate;
            columnStyle = { width: '0.4em' };
            break;
          default:
            template = null;
            columnStyle = { width: '3em' };
        }

        return (
          <Column
            key={col.field}
            field={col.field}
            header={col.header}
            style={columnStyle}
            sortable
            filter
            reorderable
            body={template}
          />
        );
      },
    );

    // alert(JSON.stringify(userOfferRows));

    return (
      <div className="appTable">
        <h3>Offers</h3>
        <br />
        <div>
          <Button
            id="handleShowAddUserOffersFormButton"
            variant="contained"
            className="pi pi-plus"
            onClick={(event) => { this.handleShowAddUserOffersForm(event); }}
          >
            Add Offer
          </Button>
          <br />
          <br />
          <div id="addOfferForm" />
        </div>

        <div>
          <br />
          <div id="updateOfferForm" />
        </div>
        <br />
        { (Array.isArray(userOfferColumns) && Array.isArray(userOfferRows))
          ? (
            <div>
              <DataTable
                value={userOfferRows}
                paginator
                className="p-datatable-responsive"
                row={this.settings.getDefaultRowsPerPageOptions()}
                rowsPerPageOptions={this.settings.getRowsPerPageOptions()}
              >
                <Column
                  key="name"
                  field="name"
                  header="Name"
                  body={this.column1BodyTemplate}
                  sortable
                  filter
                  reorderable
                  style={{ style: '31vw' }}
                />
                <Column
                  key="yearly_cost"
                  field="yearly_cost"
                  header="Cost"
                  body={this.column5BodyTemplate}
                  sortable
                  reorderable
                  style={{ style: '20vw' }}
                />
                <Column
                  key=""
                  field="actions"
                  header="Actions"
                  style={{ style: '15vw' }}
                />
              </DataTable>
            </div>
          )
          : <></> }
        <br />
        <div id="updateOfferResult" />
        <br />
        <div id="resultDataGrid" />
        <br />
        <div id="offerSchemaResult" />
        <br />
        <div id="deleteOfferResult" />
        <br />
        <div className="displayNone" id="hiddenResult" />
        <br />
      </div>
    );
  }
}

AppUserOffers.propTypes = {
  // parent: PropTypes.element,
  parent: PropTypes.object,
};

AppUserOffers.defaultProps = {
  parent: null,
};

export default AppUserOffers;
