import React, { Component } from 'react';

class AppFooter extends Component {
  constructor(props) {
    super(props);
    this.currentYear = new Date().getFullYear();
    // let copy = React.string({js|\u00a9|js});
    this.copyright = `Copyright \u00A9 ${this.currentYear}`;
  }

  render() {
    return (
      <div className="appFooter">
        {this.copyright}
        <br />
      </div>
    );
  }
}

export default AppFooter;
