import Settings from '../settings/settings';
import Utility from '../utility/utility';

class AppState {
  constructor() {
    this.settings = new Settings();
    this.utility = new Utility();

    this.loadOffersFunction = null;
    this.getOfferFilterQueryFunction = null;

    this.isLoggedin = false;
    this.token = this.settings.getDefaultClientToken();
    this.userId = 0;
    this.username = process.env.REACT_APP_DEFAULT_USERNAME || null;
    this.email = process.env.REACT_APP_DEFAULT_EMAIL || null;
    this.password = process.env.REACT_APP_DEFAULT_PASSWORD || null;
    this.website = null;
    this.role = null;
    this.userIp = null;

    this.googleMapsKey = 'AIzaSyDYwN1twEZBqDjg7lNdO2ebwd3_nFSIbkA';

    this.setUserIp();

    // used by appdebug component to rerender when a new entry is added
    this.logCallbackFunction = null;

    this.logValues = [
      // { id: 0, key: '', value: '' },
    ];

    this.loadFromLocalStorage();

    this.protocol = this.settings.getServerProtocol();
    this.host = this.settings.getServerHost();
    this.port = this.settings.getServerPort();
  }

  setLoadOffersFunction(loadOffersFunction) {
    this.loadOffersFunction = loadOffersFunction;
  }

  getLoadOffersFunction() {
    return this.loadOffersFunction;
  }

  setGetOfferFilterQueryFunction(offerFilterQueryFunction) {
    this.getOfferFilterQueryFunction = offerFilterQueryFunction;
  }

  getGetOfferFilterQueryFunction() {
    return this.getOfferFilterQueryFunction;
  }

  getBaseUrl() {
    this.clientProtocol = this.settings.getClientProtocol();
    this.clientHost = this.settings.getClientHost();
    this.clientPort = this.settings.getClientPort();
    // this.baseUrl = `${this.clientProtocol}://${this.clientHost}:${this.clientPort}/`;
    this.baseUrl = this.settings.getBaseUrl();
    return this.baseUrl;
  }

  // async
  setUserIp() {
    // this.userIp = await Utility.getUserIp();
    this.userIp = Utility.getUserIp();
  }

  getUserIp() {
    return this.userIp;
  }

  setEmail(email) {
    this.email = email;
  }

  setPasword(password) {
    this.password = password;
  }

  setWebsite(website) {
    this.website = website;
  }

  setToken(token) {
    this.token = token;
  }

  getToken() {
    return this.token;
  }

  getGoogleMapsKey() {
    return this.googleMapsKey;
  }

  log(key, value = '') {
    const id = this.logValues.length;
    this.logValues.push({ id, key, value });
    if (this.logCallbackFunction) {
      this.logCallbackFunction();
    }
  }

  logify(key, value = '') {
    const id = this.logValues.length;
    this.logValues.push({ id, key, value: JSON.stringify(value) });

    if (this.logCallbackFunction) {
      this.logCallbackFunction();
    }
  }

  setLogCallbackFunction(logCallbackFunction) {
    this.logCallbackFunction = logCallbackFunction;
  }

  logOut() {
    this.isLoggedin = false;
    this.userId = 0;
    this.token = null;
    this.email = null;
    this.password = null;
    this.website = null;
    this.role = null;
    this.userIp = null;

    this.clearLocalStorage();
  }

  saveToLocalStorage() {
    localStorage.setItem('isLoggedin', this.isLoggedin);
    localStorage.setItem('userId', this.userId);
    localStorage.setItem('token', this.token);
    localStorage.setItem('username', this.username);
    localStorage.setItem('email', this.email);
    localStorage.setItem('password', this.password);
    localStorage.setItem('website', this.website);
    localStorage.setItem('role', this.role);
    localStorage.setItem('userIp', this.userIp);
  }

  loadFromLocalStorage() {
    this.isLoggedin = localStorage.getItem('isLoggedin');
    if (this.isLoggedin) {
      this.userId = localStorage.getItem('userId');
      this.token = localStorage.getItem('token');
      this.username = localStorage.getItem('username');
      this.email = localStorage.getItem('email');
      this.password = localStorage.getItem('password');
      this.website = localStorage.getItem('website');
      this.role = localStorage.getItem('role');
      this.userIp = localStorage.getItem('userIp');
    }
  }

  clearLocalStorage() {
    localStorage.removeItem('isLoggedin');
    localStorage.removeItem('userId');
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('email');
    localStorage.removeItem('password');
    localStorage.removeItem('website');
    localStorage.removeItem('role');
    localStorage.removeItem('userIp');
    return this;
  }
}

export default AppState;
