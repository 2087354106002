import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AppOfferDetail from '../template/default/offer/appofferdetail';

class OfferDetailPage extends Component {
  constructor(props) {
    super(props);
    this.parent = props.parent;
  }

  render() {
    return (
      <>
        <AppOfferDetail parent={this} />
      </>
    );
  }
}

OfferDetailPage.propTypes = {
  // parent: PropTypes.element,
  parent: PropTypes.object,
};

OfferDetailPage.defaultProps = {
  parent: null,
};

export default OfferDetailPage;
