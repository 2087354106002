import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import Query from '../../../query/query';
import Settings from '../../../settings/settings';
import CompanyTemplateFunctions from '../company/companytemplatefunctions';

// const moment = require('moment');

class AppAdminManageCompanies extends Component {
  constructor(props) {
    super(props);
    this.parent = props.parent.parent;
    this.appState = this.parent.appState;

    this.settings = new Settings();
    this.query = new Query();
    this.ctf = new CompanyTemplateFunctions();

    // datatable templates
    this.column1BodyTemplate = this.column1BodyTemplate.bind(this);
    this.column2BodyTemplate = this.column2BodyTemplate.bind(this);
    this.column3BodyTemplate = this.column3BodyTemplate.bind(this);
    this.column4BodyTemplate = this.column4BodyTemplate.bind(this);
    this.column5BodyTemplate = this.column5BodyTemplate.bind(this);

    this.handleDeleteCompany = this.handleDeleteCompany.bind(this);

    this.handleActivateCompany = this.handleActivateCompany.bind(this);
    this.handleInActivateCompany = this.handleInActivateCompany.bind(this);

    this.loadCompanies = this.loadCompanies.bind(this);
    this.loadUsers = this.loadUsers.bind(this);

    this.setOwner = this.setOwner.bind(this);

    this.showToast = this.showToast.bind(this);

    this.state = {
      companyRows: [],
      userRows: [],
    };
  }

  componentDidMount() {
    this.loadUsers().then(
      (userRows) => {
        // const { userRows } = this.state;
        // this.showToast(JSON.stringify(userRows), 'componentDidMount');
        // alert('componentDidMount');
        // alert(userRows.length);
        console.log('loadUsers - then');
        if (userRows.length > 0) {
          this.loadCompanies();
        }
      },
    );
  }

  handleInActivateCompany(event, companyId) { // eslint-disable-line 
    event.preventDefault();

    const {
      endPoint,
      body,
    } = this.query.getInActivateCompanyEndPoint(companyId);
    console.log(`body: ${body}`);
    fetch(endPoint,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `client ${this.appState.getToken()}`,
        },
        body,
      })
      .then((response) => {
        if (response.ok) {
          response.json().then(
            (json) => {
              if (json.data.updateCompany) {
                if (json.data.updateCompany.active === false) {
                  // alert('un approved');
                  this.showToast(`inactivate companyId: ${companyId}`);
                  this.loadCompanies();
                  this.setState({
                  });
                } else {
                  // alert('not un approved');
                  // this.loadCompanies();
                  // this.loadCompanies();
                  this.showToast(`not inactivate companyId: ${companyId}`);
                  this.setState({
                  });
                }
              }
            },
          );
        } else {
          // console.log('not response.ok');
        }
      });
  }

  handleActivateCompany(event, companyId) { // eslint-disable-line 
    event.preventDefault();

    const {
      endPoint,
      body,
    } = this.query.getActivateCompanyEndPoint(companyId);
    console.log(`body: ${body}`);
    fetch(endPoint,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `client ${this.appState.getToken()}`,
        },
        body,
      })
      .then((response) => {
        if (response.ok) {
          response.json().then(
            (json) => {
              // console.log(`${JSON.stringify(json.data)}`);
              if (json.data.updateCompany) {
                if (json.data.updateCompany.active !== false) {
                  // alert('approved');
                  this.showToast(`activate companyId: ${companyId}`);
                  this.loadCompanies();
                  this.setState({
                  });
                } else {
                  this.showToast(`not activate companyId: ${companyId}`);
                  // this.loadCompanies();
                  this.setState({
                  });
                }
              }
            },
          );
        } else {
          // console.log('not response.ok');
        }
      });
  }

  handleDeleteCompany(event, companyId) { // eslint-disable-line
    event.preventDefault();

    // alert(companyId);
    const {
      endPoint,
      body,
    } = this.query.getDeleteCompanyEndPoint(companyId);
    console.log(`body: ${body}`);
    fetch(endPoint,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `client ${this.appState.getToken()}`,
        },
        body,
      })
      .then((response) => {
        if (response.ok) {
          response.json().then(
            (json) => {
              console.log(`json: ${JSON.stringify(json.data)}`);
              // if (json.data.deleteCompany) {
              // }
              if (json.data.deleteCompany) {
                // alert(json.data.deleteCompany);
                if (json.data.deleteCompany.id !== false) {
                  // alert('deleted');
                  this.showToast(`deleted companyId: ${companyId}`);
                  this.loadCompanies();
                  this.setState({
                  });
                } else {
                  // alert('not deleted');
                  this.showToast(`not deleted companyId: ${companyId}`);
                  this.setState({
                  });
                }
              } else {
                this.showToast(`not deleted companyId: ${companyId}`);
                this.loadCompanies();
                this.setState({
                });
              }
            },
          );
        } else {
          // console.log('not response.ok');
        }
      });
  }

  setOwner(event, userId) { // eslint-disable-line 
    event.preventDefault();
    // alert(`event.value: ${JSON.stringify(event.value)}`);

    /*
    const {
      owners,
    } = this.state;

    // alert(`userRoles.length: ${userRoles.length}, userId: ${userId}`);
    if (owners && (owners.length > userId)) {
      owners[userId].role = event.value.role;
    }

    if (event.value.role) {
      // alert(`event.value.role: ${event.value.role}`);
      this.handleUpdateUserRoleUser(event, userId, event.value.role);
      this.setState({
      });
    }

    this.setState({
    });
    */
  }

  showToast(detail = 'Message Content', summary: 'Success Message', severity = 'messafe', life = 10000) {
    if (this.toast) {
      this.toast.show({
        severity,
        summary,
        detail,
        life,
      });
    }
  }

  loadCompanies() {
    const body = JSON.stringify({});

    // this.showToast('loadCompanies');

    const {
      userRows,
    } = this.state;

    const companyEndPoint = this.query.getCompanyEndPoint();
    console.log(`companyEndPoint: ${companyEndPoint}`);
    fetch(companyEndPoint,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `client ${this.appState.getToken()}`,
        },
        body,
      })
      .then((response) => {
        if (response.ok) {
          // console.log('response.ok');
          response.json().then(
            (json) => {
              if (json.data) {
                if (json.data.companies) {
                  if (json.data.companies.length > 0) {
                    const companyRows = json.data.companies;

                    // const { userRows } = this.state;
                    // this.showToast('', 'loadCompanies');
                    // this.showToast(JSON.stringify(userRows), 'loadCompanies');
                    // this.showToast(JSON.stringify(userRows), 'loadCompanies');
                    console.log(`${JSON.stringify(userRows)}`);

                    companyRows.forEach((companyRow) => {
                      // alert(JSON.stringify(companyRow));
                      // if (userRows.length > companyRow.owner) {
                      // }
                      const userRole = userRows[companyRow.owner];

                      companyRow.actions = ( // eslint-disable-line no-param-reassign
                        <>
                          {(userRows.length > companyRow.owner)
                            ? (
                              <>
                                <Dropdown
                                  value={userRole}
                                  options={userRows}
                                  onChange={
                                    (e) => {
                                      this.setOwner(e, companyRow.id);
                                    }
                                  }
                                  optionLabel="username"
                                  filterBy="username"
                                  placeholder="Username"
                                  filter
                                  showClear
                                />
                                {userRows[companyRow.owner].username}
                              </>
                            ) : (
                              <>
                                {/*
                                -
                                {userRows.length}
                                -
                                {companyRow.owner}
                                -
                                */}
                              </>
                            )}
                          <br />
                          <br />
                          {(companyRow.active !== true)
                            ? (
                              <>
                                <Button
                                  className="pi pi-check"
                                  onClick={(event) => {
                                    this.handleActivateCompany(event, companyRow.id);
                                  }}
                                />
                                <br />
                                <br />
                              </>
                            )
                            : (
                              <>
                                <Button
                                  className="pi pi-times"
                                  onClick={(event) => {
                                    this.handleInActivateCompany(event, companyRow.id);
                                  }}
                                />
                                <br />
                                <br />
                              </>
                            )}
                          <Button
                            className="pi pi-trash"
                            onClick={(event) => {
                              // alert(companyRow.id);
                              this.handleDeleteCompany(event, companyRow.id);
                            }}
                          />
                          <br />
                          <br />
                        </>
                      ); // eslint-disable-line no-param-reassign
                    });

                    this.setState({
                      companyRows,
                    });
                  }
                }
              }
            },
          );
        } else {
          // console.log('not response.ok');
        }
      });
  }

  async loadUsers() {
    const returnValue = new Promise((success, fail) => { // eslint-disable-line no-unused-vars
      const {
        endPoint,
        body,
      } = this.query.getUserEndPoint();

      // console.log(`body: ${JSON.stringify(body)}`);
      fetch(endPoint,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `client ${this.appState.getToken()}`,
          },
          body,
        })
        .then((response) => {
          if (response.ok) {
            // console.log('response.ok');
            response.json().then(
              (json) => {
                if (json.data) {
                  if (json.data.users) {
                    if (json.data.users.length > 0) {
                      const userRows = json.data.users;

                      this.state.userRows = userRows;
                      // console.log('loadUsers - success');
                      return success(userRows);
                    }
                  }
                }
                return success([]);
              },
            );
          }
        });
    }).catch((error) => {
      console.log(`appadminmanagecompanies.loadUsers - error: ${error}`);
      return [];
    });
    return returnValue;
  }

  column1BodyTemplate(rowData) { // eslint-disable-line class-methods-use-this
    return (
      <>
        <strong>{`${rowData.name}`}</strong>
        <br />
      </>
    );
  }

  column2BodyTemplate(rowData) { // eslint-disable-line class-methods-use-this
    return (
      <>
        {this.ctf.formatDate(rowData.founded)}
        <br />
      </>
    );
  }

  column3BodyTemplate(rowData) { // eslint-disable-line class-methods-use-this
    return (
      <>
        {this.ctf.formatCompanyRating(rowData.company_rating)}
        <br />
      </>
    );
  }

  column4BodyTemplate(rowData) { // eslint-disable-line class-methods-use-this
    return (
      <>
        {(rowData.description !== undefined)
          ? (
            <>
              {`${rowData.description}`}
              <br />
              {(rowData.notes.length) ? `notes: ${rowData.notes}` : ''}
            </>
          )
          : (
            <>
            </>
          )}
      </>
    );
  }

  column5BodyTemplate(rowData) { // eslint-disable-line class-methods-use-this
    return (
      <>
        {(rowData.id !== undefined)
          ? (
            <>
              companies
            </>
          )
          : (
            <>
            </>
          )}
        <br />
      </>
    );
  }

  render() {
    const {
      companyRows,
    } = this.state;

    return (
      <div>
        <div>
          { (Array.isArray(companyRows))
            ? (
              <div>
                <DataTable
                  value={companyRows}
                  paginator
                  className="p-datatable-responsive"
                  row={this.settings.getDefaultRowsPerPageOptions()}
                  rowsPerPageOptions={this.settings.getRowsPerPageOptions()}
                >
                  <Column
                    key="name"
                    field="name"
                    header="Name"
                    body={this.column1BodyTemplate}
                    sortable
                    filter
                    reorderable
                  />
                  <Column
                    key="founded"
                    field="founded"
                    header="Founded"
                    body={this.column2BodyTemplate}
                    sortable
                    filter
                    reorderable
                  />
                  <Column
                    key="company_rating"
                    field="company_rating"
                    header="Rating"
                    body={this.column3BodyTemplate}
                    sortable
                    filter
                    reorderable
                  />
                  <Column
                    key="description"
                    field="description"
                    header="Description"
                    body={this.column4BodyTemplate}
                    sortable
                    reorderable
                  />
                  <Column
                    key="actions"
                    field="actions"
                    header="Actions"
                    style={{ width: '10vw' }}
                  />
                </DataTable>
                <Toast
                  position="top-right"
                  ref={(el) => {
                    this.toast = el;
                  }}
                />
              </div>
            )
            : <></> }
        </div>
      </div>
    );
  }
}

AppAdminManageCompanies.propTypes = {
  // parent: PropTypes.element,
  parent: PropTypes.object,
};

AppAdminManageCompanies.defaultProps = {
  parent: null,
};

export default AppAdminManageCompanies;
