import React from 'react';

const moment = require('moment');

class CompanyTemplateFunctions {
  constructor() { // eslint-disable-line no-useless-constructor, no-empty-function
  }

  formatCompanyImage(image, companyId, baseUrl, alt = 'logo') { // eslint-disable-line class-methods-use-this
    const noimage = `${baseUrl}no_image.png`;
    const link = `${baseUrl}p/${companyId}`;
    let returnValue = (
      <>
        <a href={link}>
          <img className="companyImage" src={noimage} alt={alt} style={{ width: '25%', display: 'none' }} />
        </a>
        <strong>{alt}</strong>
      </>
    );
    if (image) {
      returnValue = (
        <>
          <a href={link}>
            <img className="companyImage" src={image} alt={alt} style={{ width: '10vw' }} />
          </a>
        </>
      );
    }
    return returnValue;
  }

  formatDate(offerDate) { // eslint-disable-line class-methods-use-this
    // const date = moment(offerDate, 'x').format('YYYY-MM-DD hh:mm:ss');
    // const date = moment(offerDate, 'x').format('YYYY-MM-DD');
    const date = moment(offerDate, 'x').format('YYYY');
    return date;
  }

  formatCompanyRating(offerRating) { // eslint-disable-line class-methods-use-this
    let stars;
    switch (offerRating) {
      case 'zero':
        stars = (
          <>
            <i className="pi pi-star-o" />
            <i className="pi pi-star-o" />
            <i className="pi pi-star-o" />
            <i className="pi pi-star-o" />
            <i className="pi pi-star-o" />
          </>
        );
        break;
      case 'one':
        stars = (
          <>
            <i className="pi pi-star" />
            <i className="pi pi-star-o" />
            <i className="pi pi-star-o" />
            <i className="pi pi-star-o" />
            <i className="pi pi-star-o" />
          </>
        );
        break;
      case 'two':
        stars = (
          <>
            <i className="pi pi-star" />
            <i className="pi pi-star" />
            <i className="pi pi-star-o" />
            <i className="pi pi-star-o" />
            <i className="pi pi-star-o" />
          </>
        );
        break;
      case 'three':
        stars = (
          <>
            <i className="pi pi-star" />
            <i className="pi pi-star" />
            <i className="pi pi-star" />
            <i className="pi pi-star-o" />
            <i className="pi pi-star-o" />
          </>
        );
        break;
      case 'four':
        stars = (
          <>
            <i className="pi pi-star" />
            <i className="pi pi-star" />
            <i className="pi pi-star" />
            <i className="pi pi-star" />
            <i className="pi pi-star-o" />
          </>
        );
        break;
      case 'five':
        stars = (
          <>
            <i className="pi pi-star" />
            <i className="pi pi-star" />
            <i className="pi pi-star" />
            <i className="pi pi-star" />
            <i className="pi pi-star" />
          </>
        );
        break;
      default:
        stars = (
          <>
            <i className="pi pi-star-o" />
            <i className="pi pi-star-o" />
            <i className="pi pi-star-o" />
            <i className="pi pi-star-o" />
            <i className="pi pi-star-o" />
          </>
        );
    }

    return stars;
  }
}

export default CompanyTemplateFunctions;
