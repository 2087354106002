// const moment = require('moment');

function titleCase(str) {
  const splitStr = str.toLowerCase().split(' ');
  for (let i = 0; i < splitStr.length; i += 1) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
}

class CommonTemplateFunctions {
  constructor() { // eslint-disable-line no-useless-constructor, no-empty-function
  }

  formatField(field) { // eslint-disable-line class-methods-use-this
    const returnValue = titleCase(field.replace('_', ' '));
    return returnValue;
  }
}

export default CommonTemplateFunctions;
