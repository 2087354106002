import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AppMap from '../template/default/map/appmap';

class MapPage extends Component {
  constructor(props) {
    super(props);
    this.parent = props.parent;
    this.appState = props.parent.appState;
  }

  render() {
    return (
      <div>
        <AppMap parent={this} />
      </div>
    );
  }
}

MapPage.propTypes = {
  // parent: PropTypes.element,
  parent: PropTypes.object,
};

MapPage.defaultProps = {
  parent: null,
};

export default MapPage;
