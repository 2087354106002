import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AppHeaderMenu from './appheadermenu';

class AppHeader extends Component {
  constructor(props) {
    super(props);

    this.parent = props.parent;
    this.appState = props.parent.appState;
  }

  render() {
    return (
      <div className="appHeader">
        <AppHeaderMenu parent={this} />
      </div>
    );
  }
}

AppHeader.propTypes = {
  // parent: PropTypes.element,
  // https://reactjs.org/docs/typechecking-with-proptypes.html
  parent: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

AppHeader.defaultProps = {
  parent: null,
};

export default AppHeader;
