import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import Query from '../../../query/query';
import Settings from '../../../settings/settings';

class AppAdminManageUsers extends Component {
  constructor(props) {
    super(props);
    this.parent = props.parent.parent;
    this.appState = this.parent.appState;

    this.settings = new Settings();
    this.query = new Query();

    // datatable templates
    this.column1BodyTemplate = this.column1BodyTemplate.bind(this);

    this.handleDeleteUser = this.handleDeleteUser.bind(this);
    this.handleActivateUser = this.handleActivateUser.bind(this);

    this.handleUpdateUserRoleUser = this.handleUpdateUserRoleUser.bind(this);

    this.setRole = this.setRole.bind(this);

    this.loadUsers = this.loadUsers.bind(this);

    this.showToast = this.showToast.bind(this);

    const userRoles = [
      { role: 'super_admin' },
      { role: 'admin' },
      { role: 'editor' },
      { role: 'contributor' },
      { role: 'user' },
      { role: 'guest' },
    ];

    this.state = {
      // userColumns: [],
      userRows: [],
      userRoles, // eslint-disable-line react/no-unused-state
    };
  }

  componentDidMount() {
    this.loadUsers();
  }

  handleDeleteUser(event, userId) { // eslint-disable-line 
    event.preventDefault();

    const {
      endPoint,
      body,
    } = this.query.getDeleteUserEndPoint(userId);
    console.log(`body: ${body}`);
    fetch(endPoint,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `client ${this.appState.getToken()}`,
        },
        body,
      })
      .then((response) => {
        if (response.ok) {
          response.json().then(
            (json) => {
              // console.log(`${JSON.stringify(json.data)}`);
              if (json.data.deleteUser) {
                if (parseInt(json.data.deleteUser.id, 10) !== 0) {
                  this.showToast(`deleted user: ${userId}`);
                  this.loadUsers();
                  this.setState({
                  });
                } else {
                  this.showToast(`not deleted user: ${userId}`);
                  this.setState({
                  });
                }
              }
            },
          );
        } else {
          // console.log('not response.ok');
        }
      });
  }

  handleUpdateUserRoleUser(event, userId, role = "user") { // eslint-disable-line 
    event.preventDefault();

    const {
      endPoint,
      body,
    } = this.query.getUpdateUserRoleUserEndPoint(
      userId,
      role,
      this.appState.getToken(),
    );
    console.log(`body: ${body}`);
    fetch(endPoint,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `client ${this.appState.getToken()}`,
        },
        body,
      })
      .then((response) => {
        if (response.ok) {
          response.json().then(
            (json) => {
              // console.log(`${JSON.stringify(json.data)}`);
              if (json.data.updateUser) {
                if (json.data.updateUser.role === role) {
                  // alert(`role: ${role}`);
                  this.showToast(`update user: ${userId} ${role}`);
                  this.loadUsers();
                  this.setState({
                  });
                } else {
                  // alert(`role: ${role}`);
                  this.showToast(`not update user: ${userId} ${role}`);
                  this.setState({
                  });
                }
              }
            },
          );
        } else {
          // console.log('not response.ok');
        }
      });
  }

  handleInActivateUser(event, userId) { // eslint-disable-line 
    event.preventDefault();

    const {
      endPoint,
      body,
    } = this.query.getInActivateUserEndPoint(userId);
    console.log(`body: ${body}`);
    fetch(endPoint,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `client ${this.appState.getToken()}`,
        },
        body,
      })
      .then((response) => {
        if (response.ok) {
          response.json().then(
            (json) => {
              if (json.data.updateUser) {
                if (parseInt(json.data.updateUser.active, 10) === 0) {
                  this.showToast(`InActivate user: ${userId}`);
                  this.loadUsers();
                  this.setState({
                  });
                } else {
                  // alert('not un approved');
                  // this.loadUsers();
                  this.setState({
                  });
                }
              }
            },
          );
        } else {
          // console.log('not response.ok');
        }
      });
  }

  handleActivateUser(event, userId) { // eslint-disable-line 
    event.preventDefault();

    const {
      endPoint,
      body,
    } = this.query.getActivateUserEndPoint(userId);
    console.log(`body: ${body}`);
    fetch(endPoint,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `client ${this.appState.getToken()}`,
        },
        body,
      })
      .then((response) => {
        if (response.ok) {
          response.json().then(
            (json) => {
              // console.log(`${JSON.stringify(json.data)}`);
              if (json.data.updateUser) {
                if (parseInt(json.data.updateUser.active, 10) !== 0) {
                  // alert('approved');
                  this.showToast(`activated user: ${userId}`);
                  this.loadUsers();
                  this.setState({
                  });
                } else {
                  this.showToast(`not activated user: ${userId}`);
                  this.setState({
                  });
                }
              }
            },
          );
        } else {
          // console.log('not response.ok');
        }
      });
  }

  setRole(event, userId) { // eslint-disable-line 
    event.preventDefault();
    // alert(`event.value: ${JSON.stringify(event.value)}`);

    const {
      userRoles,
    } = this.state;

    // alert(`userRoles.length: ${userRoles.length}, userId: ${userId}`);
    if (userRoles && (userRoles.length > userId)) {
      userRoles[userId].role = event.value.role;
    }

    if (event.value.role) {
      // alert(`event.value.role: ${event.value.role}`);
      this.handleUpdateUserRoleUser(event, userId, event.value.role);
      this.setState({
      });
    }

    this.setState({
    });
  }

  showToast(detail = 'Message Content', summary: 'Success Message', severity = 'messafe', life = 10000) {
    if (this.toast) {
      this.toast.show({
        severity,
        summary,
        detail,
        life,
      });
    }
  }

  loadUsers() {
    const {
      userRoles,
    } = this.state;

    const {
      endPoint,
      body,
    } = this.query.getUserEndPoint();
    console.log(`body: ${body}`);
    fetch(endPoint,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `client ${this.appState.getToken()}`,
        },
        body,
      })
      .then((response) => {
        if (response.ok) {
          // console.log('response.ok');
          response.json().then(
            (json) => {
              json.data.users.forEach((userRow) => {
                userRow.actions = ( // eslint-disable-line no-param-reassign
                  <>
                    <Dropdown
                      value={{ role: userRow.role }}
                      options={userRoles}
                      onChange={
                        (e) => {
                          this.setRole(e, userRow.id);
                        }
                      }
                      optionLabel="role"
                      filterBy="role"
                      placeholder="Role"
                      filter
                      showClear
                    />
                    <br />
                    <br />
                    {(parseInt(userRow.active, 10) !== 0)
                      ? (
                        <>
                          <Button
                            label="inactivate"
                            className="pi pi-times"
                            onClick={(event) => { this.handleInActivateUser(event, userRow.id); }}
                          />
                          <br />
                          <br />
                        </>
                      )
                      : (
                        <>
                          <Button
                            label="activate"
                            className="pi pi-check"
                            onClick={(event) => { this.handleActivateUser(event, userRow.id); }}
                          />
                          <br />
                          <br />
                        </>
                      )}
                    <Button
                      className="pi pi-trash"
                      onClick={(event) => {
                        this.handleDeleteUser(event, userRow.id);
                      }}
                    />
                    <br />
                    <br />
                  </>
                ); // eslint-disable-line no-param-reassign
              });

              const userRows = json.data.users;
              // alert(JSON.stringify(userRows));

              this.setState({
                userRows,
                // userColumns,
              });
            },
          );
        } else {
          // console.log('not response.ok');
        }
      });
  }

  column1BodyTemplate(rowData) { // eslint-disable-line class-methods-use-this
    return (
      <>
        {`id: ${rowData.id}`}
        <br />
        {`username: ${rowData.username}`}
        <br />
        {`email: ${rowData.email}`}
        <br />
        {`password: ${rowData.password}`}
        <br />
        {`create_time: ${rowData.create_time}`}
        <br />
        {`website: ${rowData.website}`}
        <br />
        {`role: ${rowData.role}`}
        <br />
        {`activation_code: ${rowData.activation_code}`}
        <br />
        {`reset_password_code: ${rowData.reset_password_code}`}
        <br />
        {`active: ${(parseInt(rowData.active, 10) !== 0) ? 'active' : 'not active'}`}
        <br />
      </>
    );
  }

  render() {
    const {
      userRows,
      // userColumns,
    } = this.state;

    return (
      <>
        <>
          {/* { (Array.isArray(userColumns) && Array.isArray(userRows)) */}
          { Array.isArray(userRows)
            ? (
              <div>
                <DataTable
                  value={userRows}
                  paginator
                  className="p-datatable-responsive"
                  row={this.settings.getDefaultRowsPerPageOptions()}
                  rowsPerPageOptions={this.settings.getRowsPerPageOptions()}
                  style={{ width: '100%' }}
                >
                  <Column
                    key="email"
                    field="email"
                    header="Email"
                    body={this.column1BodyTemplate}
                    className="webkitScrollbarNone"
                    style={{
                      width: '25vw',
                    }}
                    sortable
                    filter
                    reorderable
                  />
                  <Column
                    key="actions"
                    field="actions"
                    header="Actions"
                    style={{ width: '10vw' }}
                  />
                </DataTable>
              </div>
            )
            : <></> }
        </>
        <>
          <Toast
            position="top-right"
            ref={(el) => {
              this.toast = el;
            }}
          />
        </>
      </>
    );
  }
}

AppAdminManageUsers.propTypes = {
  // parent: PropTypes.element,
  parent: PropTypes.object,
};

AppAdminManageUsers.defaultProps = {
  parent: null,
};

export default AppAdminManageUsers;
