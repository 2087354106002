import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Slider } from 'primereact/slider';
import { Button } from 'primereact/button';
import Settings from '../../../settings/settings';
import Query from '../../../query/query';
import OfferTemplateFunctions from './offertemplatefunctions';

// eslint-disable-line class-methods-use-this
// eslint-disable-line react/no-unused-state
class AppOfferFilters extends Component {
  constructor(props) {
    super(props);
    this.parent = props.parent.parent;
    this.appState = this.parent.appState;

    this.otf = new OfferTemplateFunctions();
    this.settings = new Settings();
    this.query = new Query();

    // this is used to reload offers when the filters are changed
    this.loadOffersFunction = null;

    this.setYearlyCostValues = this.setYearlyCostValues.bind(this);
    this.setRamAmountValues = this.setRamAmountValues.bind(this);
    this.setTotalStorageCapacityValues = this.setTotalStorageCapacityValues.bind(this);

    this.clearFilters = this.clearFilters.bind(this);

    this.applyFliters = this.applyFliters.bind(this);

    this.getOfferFilterQuery = this.getOfferFilterQuery.bind(this);

    const yearlyCostMaxValue = this.settings.getYearlyCostMaxValue();
    const ramAmountMaxValue = this.settings.getRamAmountMaxValue();
    const totalStorageCapacityMaxValue = this.settings.getTotalStorageCapacityMaxValue();

    // I want to pass AppOfferFilters.getOfferFilterQuery to AppOffers
    // I will use AppState as a broker because every component has it.
    // First in AppOfferFilters call
    // this.appState.setGetOfferFilterQueryFunction(this.getOfferFilterQuery);
    // then in AppOffers
    // const offerFilterQuery = appState.getOfferFilterQueryFunction();
    this.appState.setGetOfferFilterQueryFunction(this.getOfferFilterQuery);

    this.state = {
      yearlyCostMaxValue,
      yearlyCostValues: [0, yearlyCostMaxValue],
      ramAmountMaxValue,
      ramAmountValues: [0, ramAmountMaxValue],
      totalStorageCapacityMaxValue,
      totalStorageCapacityValues: [0, totalStorageCapacityMaxValue],
      offset: 0,
      limit: this.settings.getPageSizeLimit(),
    };
  }

  componentDidMount() {
    this.loadOffersFunction = this.appState.getLoadOffersFunction();
  }

  getOfferFilterQuery() {
    const {
      offset,
      limit,
      yearlyCostValues,
      ramAmountValues,
      totalStorageCapacityValues,
    } = this.state;

    const returnValue = this.query.getOfferFilterQuery(
      yearlyCostValues,
      ramAmountValues,
      totalStorageCapacityValues,
      offset,
      limit,
      this.appState.getToken(),
    );
    return returnValue;

    /*
    const query = `query offersFilterQuery($yearly_cost_min: Float = 0, $yearly_cost_max: Float = 0,
      $total_storage_capacity_min: Int = 0, $total_storage_capacity_max: Int = 1000000000,
      $ram_amount_min: Int = 0, $ram_amount_max: Int = 1000000000,
      $offset: Int=0, $limit: Int=20, $token: String = "")
      { offersFilter(yearly_cost_min: $yearly_cost_min, yearly_cost_max: $yearly_cost_max,
        total_storage_capacity_min: $total_storage_capacity_min,
        total_storage_capacity_max: $total_storage_capacity_max,
        ram_amount_min: $ram_amount_min, ram_amount_max: $ram_amount_max,
        offset: $offset, limit: $limit, token: $token)
      {
        id
        owner
        company{ id name founded notes description country_code company_rating image active }
        name
        service_type
        location_iso_3166_alpha_2_country_code
        location
        longitude
        latitude
        order_link
        yearly_cost
        second_year_cost
        ram_amount
        ram_type
        cpu_cores_amount
        cpu_cores_type
        cpu_type
        monthly_bandwidth
        external_network_port_speed
        total_storage_capacity
        storage_type
        ipv4_addresses
        additional_ip4_available
        ipv6_addresses
        looking_glass_link
        initial_offer_date
        expiration_date
        aup
        control_panel_type
        support_method
        support_response_time
        management_type
        windows
        custom_iso
        control_via_api
        root_access
        ddos_protection
        softaculous
        notes
        payment_methods
        setup_time
        setup_fee
        offer_rating
        shortcode
        last_modified
        active
      } }`;

    let variables = {};

    variables.token = this.appState.getToken();

    variables.yearly_cost_min = parseFloat(yearlyCostValues[0], 10);
    variables.yearly_cost_max = parseFloat(yearlyCostValues[1], 10);

    variables.ram_amount_min = parseInt(ramAmountValues[0], 10);
    variables.ram_amount_max = parseInt(ramAmountValues[1], 10);

    variables.total_storage_capacity_min = parseInt(totalStorageCapacityValues[0], 10);
    variables.total_storage_capacity_max = parseInt(totalStorageCapacityValues[1], 10);

    variables.offset = offset;
    variables.limit = limit;

    variables = JSON.stringify(variables);

    const endpoint = `${this.settings.getServerProtocol()}://${this.settings.getServerHost()}:${this.settings.getServerPort()}/api/protected/graphql`;
    const operationName = 'offersFilterQuery';

    const body = JSON.stringify({
      variables,
      operationName,
      query,
    });

    const returnValue = {
      endpoint,
      body,
    };

    return returnValue;
    */
  }

  setYearlyCostValues(value) {
    const yearlyCostValues = [value[0], value[1]];

    this.setState({
      yearlyCostValues,
    });
  }

  setRamAmountValues(value) {
    const ramAmountValues = [value[0], value[1]];

    this.setState({
      ramAmountValues,
    });
  }

  setTotalStorageCapacityValues(value) {
    const totalStorageCapacityValues = [value[0], value[1]];

    this.setState({
      totalStorageCapacityValues,
    });
  }

  applyFliters() { // eslint-disable-line class-methods-use-this
    console.log(`appofferfilters.applyFliters ${this.appState.loadOffersFunction}`);
    if (this.loadOffersFunction) {
      // alert(this.loadOffersFunction);
      this.loadOffersFunction();
    } else {
      // alert(this.loadOffersFunction);
      // this.query.setOfferFilterQueryFunction(this.getOfferFilterQuery);
      this.loadOffersFunction = this.query.getLoadOffersFunction();
      if (this.loadOffersFunction) {
        this.loadOffersFunction();
      }
    }
  }

  clearFilters() {
    const {
      yearlyCostMaxValue,
      ramAmountMaxValue,
      totalStorageCapacityMaxValue,
    } = this.state;

    let {
      yearlyCostValues,
      ramAmountValues,
      totalStorageCapacityValues,
    } = this.state;

    yearlyCostValues = [0, yearlyCostMaxValue];
    ramAmountValues = [0, ramAmountMaxValue];
    totalStorageCapacityValues = [0, totalStorageCapacityMaxValue];

    this.setState({
      yearlyCostValues,
      ramAmountValues,
      totalStorageCapacityValues,
    });
  }

  render() {
    const {
      yearlyCostMaxValue,
      yearlyCostValues,
      ramAmountMaxValue,
      ramAmountValues,
      totalStorageCapacityMaxValue,
      totalStorageCapacityValues,
    } = this.state;

    return (
      <div className="appFilter">
        <Accordion>
          <AccordionTab header="Filter">
            <>
              <Accordion>
                <AccordionTab header="Price">
                  <br />
                  <strong>
                    {`Yearly Cost Values ($USD): ${this.otf.formatCostRangeValue(yearlyCostValues[0])} - ${this.otf.formatCostRangeValue(yearlyCostValues[1])}`}
                  </strong>
                  <br />
                  <br />
                  <Slider
                    range
                    max={yearlyCostMaxValue}
                    value={yearlyCostValues}
                    onChange={(e) => this.setYearlyCostValues(e.value)}
                  />
                  <br />
                </AccordionTab>
                <AccordionTab header="RAM Amount">
                  <br />
                  <strong>
                    {`RAM Amount Values (GB): ${this.otf.formatRamRangeValue(ramAmountValues[0])} - ${this.otf.formatRamRangeValue(ramAmountValues[1])}`}
                  </strong>
                  <br />
                  <br />
                  <Slider
                    range
                    max={ramAmountMaxValue}
                    value={ramAmountValues}
                    onChange={(e) => this.setRamAmountValues(e.value)}
                  />
                  <br />
                </AccordionTab>
                <AccordionTab header="Total Storage Capacity">
                  <br />
                  <strong>
                    {`Total Storage Capacity Values (GB): ${this.otf.formatStorageRangeValue(totalStorageCapacityValues[0])} - ${this.otf.formatStorageRangeValue(totalStorageCapacityValues[1])}`}
                  </strong>
                  <br />
                  <br />
                  <Slider
                    range
                    max={totalStorageCapacityMaxValue}
                    value={totalStorageCapacityValues}
                    onChange={(e) => this.setTotalStorageCapacityValues(e.value)}
                  />
                  <br />
                </AccordionTab>
              </Accordion>
              <br />
              <Button
                label="Clear Filters"
                icon="pi pi-refresh"
                onClick={() => {
                  this.clearFilters();
                }}
              />
              {' '}
              <Button
                label="Apply Filters"
                icon="pi pi-refresh"
                onClick={() => {
                  this.applyFliters();
                }}
              />
              { (this.appState.role === 'super_admin')
                ? (
                  <>
                    <br />
                    <br />
                  </>
                )
                : (
                  <>
                  </>
                )}
            </>
          </AccordionTab>
        </Accordion>
      </div>
    );
  }
}

AppOfferFilters.propTypes = {
  // parent: PropTypes.element,
  parent: PropTypes.object,
};

AppOfferFilters.defaultProps = {
  parent: null,
};

export default AppOfferFilters;
