import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

class LogOutPage extends Component {
  constructor(props) {
    super(props);
    this.parent = props.parent;
    this.appState = props.parent.appState;

    this.appState.logOut();
    this.setState({});
    this.parent.setState({});
  }

  render() {
    return (
      <div>
        You are now logged out.
        <br />
      </div>
    );
  }
}

LogOutPage.propTypes = {
  // parent: PropTypes.element,
  parent: PropTypes.object,
};

LogOutPage.defaultProps = {
  parent: null,
};

export default LogOutPage;
