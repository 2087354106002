import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AppOffers from '../template/default/offer/appoffers';

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.parent = props.parent;
  }

  render() {
    return (
      <>
        <AppOffers parent={this} />
      </>
    );
  }
}

HomePage.propTypes = {
  // parent: PropTypes.element,
  parent: PropTypes.object,
};

HomePage.defaultProps = {
  parent: null,
};

export default HomePage;
