import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';
import { GMap } from 'primereact/gmap';
import Query from '../../../query/query';
import Settings from '../../../settings/settings';
import Comment from '../comment/comment';
import OfferTemplateFunctions from './offertemplatefunctions';

class AppOfferDetail extends Component {
  constructor(props) {
    super(props);
    this.parent = props.parent.parent;
    this.appState = this.parent.appState;

    this.otf = new OfferTemplateFunctions();
    this.query = new Query();
    this.settings = new Settings();

    const {
      match,
    } = this.props;

    const { params } = match;
    const { shortcode } = params;

    this.state = {
      rowData: null,
      shortcode,
    };

    this.loadOfferbyShortcode = this.loadOfferbyShortcode.bind(this);
    // this.loadOfferbyShortcode();
  }

  componentDidMount() {
    this.loadOfferbyShortcode();
  }

  loadOfferbyShortcode() {
    const {
      shortcode,
    } = this.state;

    const {
      endPoint,
      body,
    } = this.query.getOfferByShortcodeQuery(shortcode);
    console.log(JSON.stringify(body));
    fetch(endPoint,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `client ${this.appState.getToken()}`,
        },
        body,
      })
      .then((response) => {
        if (response.ok) {
          // console.log('response.ok');
          response.json().then(
            (json) => {
              // let rowData = null;
              if (Array.isArray(json.data.offers) && (json.data.offers.length > 0)) {
                const { data } = json;
                const { offers } = data;
                const rowData = offers[0];
                this.setState({ rowData });
              }
            },
          );
        } else {
          // console.log('not response.ok');
        }
      });
  }

  render() {
    const {
      rowData,
      shortcode,
    } = this.state;

    if (rowData === null) {
      return (<></>);
    }

    let shortcodeUrl = null;
    let orderLink = null;
    let lookingGlassLink = null;
    let shortcodeLink = null;

    let mapOptions = null;
    let mapOverlays = null;
    let googleMapsReady = null;

    if ((rowData !== undefined) && (rowData !== null)) {
      shortcodeUrl = `${this.appState.getBaseUrl()}o/${rowData.shortcode}`; // eslint-disable-line no-unused-vars
      orderLink = (rowData.order_link) ? rowData.order_link : '';
      if (orderLink !== '') {
        orderLink = (orderLink.indexOf('://') === -1) ? `https://${orderLink}` : orderLink;
      }

      lookingGlassLink = (rowData.looking_glass_link) ? rowData.looking_glass_link : '';
      if (lookingGlassLink !== '') {
        lookingGlassLink = (lookingGlassLink.indexOf('://') === -1) ? `https://${lookingGlassLink}` : lookingGlassLink;
      }

      shortcodeLink = (rowData.shortcode) ? rowData.shortcode : '#';
      shortcodeLink = (shortcodeLink.indexOf('://') === -1) ? `https://${shortcodeLink}` : shortcodeLink; // eslint-disable-line no-unused-vars

      mapOptions = {
        center: {
          lat: parseFloat(rowData.latitude),
          lng: parseFloat(rowData.longitude),
        },
        zoom: 3,
        type: 'terrain',
        mapTypeControl: false,
        zoomControl: false,
        streetViewControl: false,
      };
      mapOverlays = [
        new google.maps.Marker({ // eslint-disable-line no-undef
          position: {
            lat: parseFloat(rowData.latitude),
            lng: parseFloat(rowData.longitude),
          },
          title: rowData.name,
          draggable: false,
        }),
      ];
      googleMapsReady = true;
    }

    return (
      <>
        { (shortcode !== undefined)
          ? (
            <>
              { ((rowData !== undefined) && (rowData !== null))
                ? (
                  <>
                    <h1>{`${rowData.name}`}</h1>
                    <Accordion
                      multiple
                      activeIndex={[0, 1, 2, 3, 4, 5]}
                    >
                      <AccordionTab header="Specs">
                        <>
                          <strong>{`${rowData.name}`}</strong>
                          <br />
                          <br />
                          {/*
                          {this.otf.formatOfferRating(rowData.offer_rating)}
                          <br />
                          */}
                          <strong>Company</strong>
                          {': '}
                          {(rowData.company)
                            ? (
                              <>
                                {this.otf.formatCompanyName(
                                  rowData.company.name,
                                  rowData.company.id,
                                  this.appState.getBaseUrl(),
                                )}
                              </>
                            )
                            : (
                              <>
                              </>
                            )}
                          <br />
                          <strong>CPU</strong>
                          {': '}
                          {`${rowData.cpu_cores_amount} ${rowData.cpu_cores_type} Core(s) ${rowData.cpu_type} ${this.otf.formatCpuSpeed(rowData.cpu_speed)}`}
                          <br />
                          <strong>RAM</strong>
                          {': '}
                          {`${this.otf.formatRam(rowData.ram_amount)} ${this.otf.formatRamType(rowData.ram_type)}`}
                          <br />
                          <strong>Total Storage</strong>
                          {': '}
                          {`${this.otf.formatTotalStorageCapacity(rowData.total_storage_capacity)} ${this.otf.formatStorageType(rowData.storage_type)}`}
                          <br />
                          <br />
                          {/*
                          <Button
                            label="Details"
                            icon="pi pi-search"
                            href={shortcodeLink}
                            rel="noreferrer"
                            onClick={
                              (e) => {
                                e.preventDefault();
                                window.open(shortcodeLink, '_top').focus();
                              }
                            }
                          />
                          */}
                          <br />
                        </>
                      </AccordionTab>
                      <AccordionTab header="Description">
                        <>
                          <strong>Service Type</strong>
                          {': '}
                          {`${this.otf.formatServiceType(rowData.service_type)}`}
                          <br />
                          <br />
                          <strong>Management Type</strong>
                          {': '}
                          {`${this.otf.formatFieldName(rowData.management_type)}`}
                          <br />
                          <br />
                          <strong>Notes</strong>
                          {': '}
                          {`${rowData.notes}`}
                          <br />
                          <br />
                          <strong>Last Modified</strong>
                          {': '}
                          {`${this.otf.formatDate(rowData.last_modified)}`}
                          <br />
                        </>
                      </AccordionTab>
                      <AccordionTab header="Network">
                        <>
                          {(parseFloat(rowData.monthly_bandwidth, 10) > 0)
                            ? (
                              <>
                                <strong>Bandwidth: </strong>
                                {`${this.otf.formatMonthlyBandwidth(rowData.monthly_bandwidth)} /m`}
                                <br />
                              </>
                            )
                            : (
                              <>
                              </>
                            )}
                          {(rowData.unmetered_traffic_inbound)
                            ? (
                              <>
                                <strong>Unmetered Traffic Inbound: </strong>
                                {`${this.otf.formatBoolean(rowData.unmetered_traffic_inbound)}`}
                                <br />
                              </>
                            )
                            : (
                              <>
                                <br />
                              </>
                            )}
                          {(rowData.unmetered_traffic_outbound)
                            ? (
                              <>
                                <strong>Unmetered Traffic Outbound: </strong>
                                {`${this.otf.formatBoolean(rowData.unmetered_traffic_outbound)}`}
                                <br />
                              </>
                            )
                            : (
                              <>
                                <br />
                              </>
                            )}
                          <strong>External Port Speed</strong>
                          {': '}
                          {`${this.otf.formatFieldName(rowData.external_network_port_speed)}`}
                          <br />
                          <strong>IPv4</strong>
                          {': '}
                          {`${rowData.ipv4_addresses} ${(rowData.additional_ip4_available) ? 'Additional IPv4 Addresses are Available' : ''}`}
                          <br />
                          {(rowData.ipv6_addresses)
                            ? (
                              <>
                                <strong>IPv6: </strong>
                                {`${rowData.ipv6_addresses}`}
                                <br />
                              </>
                            )
                            : (
                              <>
                                <br />
                              </>
                            )}
                          <strong>Distributed Denial of Service (DDOS) Protection</strong>
                          {': '}
                          {`${this.otf.formatBoolean(rowData.ddos_protection)}`}
                          <br />
                          <br />
                          <Button
                            label="Looking Glass"
                            icon="pi pi-search"
                            href={lookingGlassLink}
                            rel="noreferrer"
                            onClick={
                              (e) => {
                                e.preventDefault();
                                if (lookingGlassLink !== undefined && lookingGlassLink !== null && lookingGlassLink !== '') {
                                  window.open(lookingGlassLink, '_blank').focus();
                                }
                              }
                            }
                          />
                          <br />
                        </>
                      </AccordionTab>
                      <AccordionTab header="Location">
                        <>
                          <strong>Location</strong>
                          {': '}
                          {`${rowData.location_iso_3166_alpha_2_country_code} ${rowData.location}`}
                          <br />
                          <br />
                          <>
                            {(googleMapsReady)
                              ? (
                                <>
                                  <GMap
                                    options={mapOptions}
                                    overlays={mapOverlays}
                                    style={{ width: '100%', minHeight: '40vh' }}
                                  />
                                </>
                              ) : (
                                <>
                                </>
                              )}
                          </>
                        </>
                      </AccordionTab>
                      <AccordionTab header="Features">
                        <>
                          <strong>Initial Offer Date</strong>
                          {': '}
                          {`${this.otf.formatDate(rowData.initial_offer_date)}`}
                          <br />
                          <strong>Expiration Date</strong>
                          {': '}
                          {`${this.otf.formatDate(rowData.expiration_date)}`}
                          <br />
                          <strong>Payment Methods</strong>
                          {': '}
                          {`${this.otf.formatFieldName(rowData.payment_methods)}`}
                          <br />
                          <strong>Setup Time</strong>
                          {': '}
                          {`${this.otf.formatFieldName(rowData.setup_time)}`}
                          <br />
                          <strong>Support Methods</strong>
                          {': '}
                          {`${this.otf.formatFieldName(rowData.support_method)}`}
                          <br />
                          <strong>Support Response Time</strong>
                          {': '}
                          {`${this.otf.formatFieldName(rowData.support_response_time)}`}
                          <br />
                          <strong>Control Panel Type</strong>
                          {': '}
                          {`${this.otf.formatFieldName(rowData.control_panel_type)}`}
                          <br />
                          <strong>Windows</strong>
                          {': '}
                          {`${this.otf.formatBoolean(rowData.windows)}`}
                          <br />
                          <strong>Custom ISO</strong>
                          {': '}
                          {`${this.otf.formatBoolean(rowData.custom_iso)}`}
                          <br />
                          <strong>Control Via API</strong>
                          {': '}
                          {`${this.otf.formatBoolean(rowData.control_via_api)}`}
                          <br />
                          <strong>Root Access</strong>
                          {': '}
                          {`${this.otf.formatBoolean(rowData.root_access)}`}
                          <br />
                          <strong>Softaculous</strong>
                          {': '}
                          {`${this.otf.formatBoolean(rowData.softaculous)}`}
                          <br />
                          <strong>Acceptable Use Policy</strong>
                          {': '}
                          {`${this.otf.formatFieldName(rowData.aup)}`}
                          <br />
                        </>
                      </AccordionTab>
                      <AccordionTab header="Pricing">
                        <>
                          {(rowData.yearly_cost > 0)
                            ? (
                              <>
                                <strong>Yearly Cost: </strong>
                                {`${this.otf.formatCost(rowData.yearly_cost)}`}
                                /y
                                <br />
                              </>
                            )
                            : (
                              <>
                              </>
                            )}
                          {/*
                          <strong>First Year Cost</strong>
                          {': '}
                          {`${this.otf.formatCost(rowData.yearly_cost)} /y`}
                          <br />
                          */}
                          {(rowData.monthly_cost > 0)
                            ? (
                              <>
                                <strong>Monthly Cost: </strong>
                                {`${this.otf.formatCost(rowData.monthly_cost)}`}
                                /m
                                <br />
                              </>
                            )
                            : (
                              <>
                              </>
                            )}
                          <br />
                          {(rowData.second_year_cost > 0)
                            ? (
                              <>
                                <strong>Second Year Cost: </strong>
                                {`${this.otf.formatCost(rowData.second_year_cost)}`}
                                /y
                                <br />
                              </>
                            )
                            : (
                              <>
                              </>
                            )}
                          {(rowData.setup_fee > 0)
                            ? (
                              <>
                                <strong>Setup Fee: </strong>
                                {`${this.otf.formatCost(rowData.setup_fee)}`}
                                /y
                                <br />
                              </>
                            )
                            : (
                              <>
                              </>
                            )}
                          <br />
                          <Button
                            label="Buy"
                            icon="pi pi-shopping-cart"
                            href={orderLink}
                            rel="noreferrer"
                            title="order"
                            onClick={
                              (e) => {
                                e.preventDefault();
                                if (orderLink !== undefined && orderLink !== null && orderLink !== '') {
                                  window.open(orderLink, '_blank').focus();
                                }
                              }
                            }
                          />
                        </>
                      </AccordionTab>
                      {(this.appState.role === 'super_admin' && this.settings.getAllowComments())
                        ? (
                          <AccordionTab header="Comments">
                            <Comment parent={this} />
                          </AccordionTab>
                        )
                        : (
                          <>
                          </>
                        )}
                    </Accordion>
                  </>
                ) : (
                  <>
                    No offer with that shortcode exists.
                  </>
                )}
            </>
          )
          : (
            <>
              Empty shortcode.
            </>
          )}
      </>
    );
  }
}

AppOfferDetail.propTypes = {
  // parent: PropTypes.element,
  parent: PropTypes.object,
  // match: PropTypes.element.isRequired,
  match: PropTypes.object.isRequired,

  // location: PropTypes.element.isRequired,
  // history: PropTypes.element.isRequired,
};

AppOfferDetail.defaultProps = {
  parent: null,
  // match: null, // if its required it shouldt have a default
  // location: null,
  // history: null,
};

export default withRouter(AppOfferDetail);
