import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AppCompanies from '../template/default/company/appcompanies';

class CompanyPage extends Component {
  constructor(props) {
    super(props);
    this.parent = props.parent;
    this.appState = props.parent.appState;
  }

  render() {
    return (
      <div className="appTable">
        <h1>Providers</h1>
        <AppCompanies parent={this} />
        <br />
      </div>
    );
  }
}

CompanyPage.propTypes = {
  // parent: PropTypes.element,
  parent: PropTypes.object,
};

CompanyPage.defaultProps = {
  parent: null,
};

export default CompanyPage;
