class Settings {
  static allowComments = true; // eslint-disable-line import/no-named-as-default, import/no-named-as-default-member, max-len

  constructor() {
    // clients are connecting to us at
    this.clientProtocol = 'https';
    this.clientHost = 'lowendoffer.win';
    this.clientPort = 3000;

    // server we are connecting to
    this.serverProtocol = 'https';
    this.serverHost = 'lowendoffer.win';
    this.serverPort = 3001;

    this.defaultClientToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2NvdW50Ijp7InVzZXJJZCI6NDMsImlzc3VlciI6Imxlby1zZXJ2ZXIiLCJ1c2VybmFtZSI6ImRlZmF1bHRfY2xpZW50QGxvd2VuZG9mZmVyLndpbiIsImVtYWlsIjoiZGVmYXVsdF9jbGllbnRAbG93ZW5kb2ZmZXIud2luIiwicGFzc3dvcmQiOiJlOWYyYTFlMzVhYTZhNzlhM2Y1YTNhZDEyZTg2MDk4NiIsIndlYnNpdGUiOiJsb3dlbmRvZmZlci53aW4iLCJpc3N1ZWQtYXQiOiIyMDIxLTA2LTA5IDIwOjA2OjczIiwiZXhwaXJhdGlvbiI6IjIwMjEtMDYtMDkgMjA6MDY6NzMiLCJyb2xlIjoidXNlciIsImNsaWVudElwIjoiOjpmZmZmOjY1LjQ4LjE4Ni40IiwidXNlcklwIjoiIn0sImlhdCI6MTYyMzI4NDE2M30.OA9k4ShWotUnbDZ8D4vzuYcKyF5eYSZDMPhZlRx7TRc';

    // this.yearlyCostMaxValue = 1000000; // $US 1,000,000
    this.yearlyCostMaxValue = 1000; // $US 30,000
    this.ramAmountMaxValue = 1000; // GB
    this.totalStorageCapacityMaxValue = 1000000; // GB

    this.pageSizeLimit = 50; // results at a time

    // must not be empty
    // this.rowsPerPageOptions = [20, 50, 100];
    this.rowsPerPageOptions = [20, 50, 100];

    this.googleMapsKey = 'AIzaSyDYwN1twEZBqDjg7lNdO2ebwd3_nFSIbkA';
  }

  getDefaultRowsPerPageOptions() {
    if (this.rowsPerPageOptions.length > 0) {
      return this.rowsPerPageOptions[0];
    }
    return 20;
  }

  getRowsPerPageOptions() {
    return this.rowsPerPageOptions;
  }

  getAllowComments() {
    return this.allowComments;
  }

  setAllowComments(allowComments) {
    this.allowComments = allowComments;
  }

  getGoogleMapsKey() {
    return this.googleMapsKey;
  }

  getEnvrionment() { // eslint-disable-line class-methods-use-this
    return process.env.NODE_ENV;
  }

  getBaseUrl() {
    this.clientProtocol = 'https';
    this.baseUrl = `${this.clientProtocol}://${this.clientHost}:${this.clientPort}/`;

    switch (process.env.NODE_ENV) {
      case 'development':
        this.baseUrl = `${this.clientProtocol}://${this.clientHost}:${this.clientPort}/`;
        break;
      case 'staging':
        this.clientPort = 443;
        this.baseUrl = `${this.clientProtocol}://${this.clientHost}:${this.clientPort}/staging/`;
        break;
      case 'production':
        this.clientPort = 443;
        this.baseUrl = `${this.clientProtocol}://${this.clientHost}:${this.clientPort}/`;
        break;
      default:
    }
    return this.baseUrl;
  }

  getYearlyCostMaxValue() {
    return this.yearlyCostMaxValue;
  }

  getRamAmountMaxValue() {
    return this.ramAmountMaxValue;
  }

  getTotalStorageCapacityMaxValue() {
    return this.totalStorageCapacityMaxValue;
  }

  getPageSizeLimit() {
    return this.pageSizeLimit;
  }

  static isDevelopmnet() {
    if (process.env.NODE_ENV === 'development') {
      return true;
    }
    return false;
  }

  static isProduction() {
    if (process.env.NODE_ENV === 'production') {
      return true;
    }
    return false;
  }

  getClientProtocol() {
    return this.clientProtocol;
  }

  getClientHost() {
    return this.clientHost;
  }

  getClientPort() {
    return this.clientPort;
  }

  getServerProtocol() {
    return this.serverProtocol;
  }

  getServerHost() {
    return this.serverHost;
  }

  getServerPort() {
    return this.serverPort;
  }

  getDefaultClientToken() {
    return this.defaultClientToken;
  }
}

export default Settings;
