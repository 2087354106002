import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Settings from '../../../settings/settings';
import Query from '../../../query/query';
import CompanyTemplateFunctions from './companytemplatefunctions';

class AppCompanies extends Component {
  constructor(props) {
    super(props);
    this.parent = props.parent.parent;
    this.appState = this.parent.appState;

    this.ctf = new CompanyTemplateFunctions();
    this.settings = new Settings();
    this.query = new Query();

    // datatable templates
    this.column1BodyTemplate = this.column1BodyTemplate.bind(this);
    this.column2BodyTemplate = this.column2BodyTemplate.bind(this);
    this.column3BodyTemplate = this.column3BodyTemplate.bind(this);
    this.column4BodyTemplate = this.column4BodyTemplate.bind(this);

    this.loadCompanies = this.loadCompanies.bind(this);

    this.state = {
      companyColumns: [],
      companyRows: [],
    };
  }

  componentDidMount() {
    this.loadCompanies();
  }

  loadCompanies() {
    const {
      endPoint,
      body,
    } = this.query.getCompanyPageEndPoint();

    fetch(endPoint,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `client ${this.appState.getToken()}`,
        },
        body,
      })
      .then((response) => {
        if (response.ok) {
          // console.log('response.ok');
          response.json().then(
            (json) => {
              // this.appState.setCompanyJson(json.data.companies);
              // this.setState({}); // rerender page

              // this.appState.setCompanyJson(json.data.companies);

              // alert(JSON.stringify(json.data.companies));
              // const ufferJson = this.appState.getCompanyJson();

              const offerJson = json.data.companies;

              const companyColumns = [];
              const firstRow = offerJson[0];
              const columns = Object.keys(firstRow);
              columns.forEach((column) => {
                companyColumns.push({
                  field: column,
                  header: column,
                  key: column,
                  name: column,
                  filterable: true,
                  sortable: true,
                  editable: true,
                  autoLayout: true,
                });
              });

              const companyRows = json.data.companies;

              this.setState({ companyColumns, companyRows });
            },
          );
        } else {
          // console.log('not response.ok');
        }
      });
  }

  column1BodyTemplate(rowData) { // eslint-disable-line class-methods-use-this
    // alert(JSON.stringify(rowData.image));
    return (
      <>
        {/*
        <strong>{`${rowData.name}`}</strong>
        <br />
        */}
        {this.ctf.formatCompanyImage(rowData.image,
          rowData.id,
          this.settings.getBaseUrl(),
          rowData.name)}
      </>
    );
  }

  column2BodyTemplate(rowData) { // eslint-disable-line class-methods-use-this
    return (
      <>
        {this.ctf.formatDate(rowData.founded)}
        <br />
      </>
    );
  }

  column3BodyTemplate(rowData) { // eslint-disable-line class-methods-use-this
    return (
      <>
        {this.ctf.formatCompanyRating(rowData.company_rating)}
        <br />
      </>
    );
  }

  column4BodyTemplate(rowData) { // eslint-disable-line class-methods-use-this
    return (
      <>
        {(rowData.description !== undefined)
          ? (
            <>
              {`${rowData.description}`}
              <br />
              {(rowData.notes.length) ? `notes: ${rowData.notes}` : ''}
            </>
          )
          : (
            <>
            </>
          )}
      </>
    );
  }

  render() {
    const {
      companyRows,
      companyColumns,
    } = this.state;

    const dynamicColumns = companyColumns.map( // eslint-disable-line no-unused-vars
      // filter
      (col) => (
        <Column
          key={col.field}
          field={col.field}
          header={col.header}
          sortable
          reorderable
        />
      ),
    );

    return (
      <div>
        <div>
          { (Array.isArray(companyColumns) && Array.isArray(companyRows))
            ? (
              <div>
                <DataTable
                  value={companyRows}
                  paginator
                  className="p-datatable-responsive"
                  row={this.settings.getDefaultRowsPerPageOptions()}
                  rowsPerPageOptions={this.settings.getRowsPerPageOptions()}
                >
                  <Column
                    key="name"
                    field="name"
                    header="Name"
                    body={this.column1BodyTemplate}
                    sortable
                    filter
                    reorderable
                    style={{ width: '20vw' }}
                  />
                  <Column
                    key="founded"
                    field="founded"
                    header="Founded"
                    body={this.column2BodyTemplate}
                    sortable
                    reorderable
                    style={{ width: '13vw' }}
                  />
                  {/*
                  <Column
                    key="company_rating"
                    field="company_rating"
                    header="Rating"
                    body={this.column3BodyTemplate}
                    sortable
                    reorderable
                    style={{ width: '10vw' }}
                  />
                  */}
                  <Column
                    key="description"
                    field="description"
                    header="Description"
                    className="webkitScrollbarNone"
                    body={this.column4BodyTemplate}
                    sortable
                    reorderable
                    style={{
                      width: '20vw',
                    }}
                  />
                </DataTable>
              </div>
            )
            : <></> }
        </div>
      </div>
    );
  }
}

AppCompanies.propTypes = {
  // parent: PropTypes.element,
  parent: PropTypes.object,
};

AppCompanies.defaultProps = {
  parent: null,
};

export default AppCompanies;
