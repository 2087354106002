import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Menubar } from 'primereact/menubar';
import { InputText } from 'primereact/inputtext';
import Settings from '../../../settings/settings';

class AppHeaderMenu extends Component {
  constructor(props) {
    super(props);
    this.parent = props.parent;
    this.appState = this.parent.appState;
    this.settings = new Settings();

    this.state = {};
  }

  render() {
    const {
      isLoggedin,
    } = this.appState;

    const start = <a href={`${this.settings.getBaseUrl()}home`}><img src={`${this.settings.getBaseUrl()}leo_logo.png`} alt="low end offer" /></a>;
    let end = <></>;

    let menuItems;
    if (isLoggedin) {
      end = (
        <InputText
          placeholder="Search"
          type="text"
          style={{
            width: '90%',
            display: 'none',
          }}
        />
      );

      menuItems = [
        { label: 'Home', icon: 'pi pi-home', url: `${this.settings.getBaseUrl()}home` },
        { label: 'Map', icon: 'pi pi-map-marker', url: `${this.settings.getBaseUrl()}map` },
        { label: 'Providers', icon: 'pi pi-flag', url: `${this.settings.getBaseUrl()}providers` },
        { label: 'About Us', icon: 'pi pi-book', url: `${this.settings.getBaseUrl()}about-us` },
        { label: 'Contact Us', icon: 'pi pi-send', url: `${this.settings.getBaseUrl()}contact-us` },
        {
          label: 'Profile',
          icon: 'pi pi-user',
          items: [
            { label: 'Dashboard', icon: 'pi pi-user', url: `${this.settings.getBaseUrl()}dashboard` },
            { label: 'Logout', icon: 'pi pi-sign-out', url: `${this.settings.getBaseUrl()}logout` },
          ],
        },
      ];
    } else {
      menuItems = [
        { label: 'Home', icon: 'pi pi-home', url: `${this.settings.getBaseUrl()}home` },
        { label: 'Map', icon: 'pi pi-map-marker', url: `${this.settings.getBaseUrl()}map` },
        { label: 'Providers', icon: 'pi pi-flag', url: `${this.settings.getBaseUrl()}providers` },
        { label: 'About Us', icon: 'pi pi-book', url: `${this.settings.getBaseUrl()}about-us` },
        { label: 'Contact Us', icon: 'pi pi-send', url: `${this.settings.getBaseUrl()}contact-us` },
        { label: 'Login', icon: 'pi pi-sign-in', url: `${this.settings.getBaseUrl()}login` },
      ];
    }

    return (
      <>
        <Menubar
          model={menuItems}
          start={start}
          end={end}
        />
      </>
    );
  }
}

AppHeaderMenu.propTypes = {
  // parent: PropTypes.element,
  parent: PropTypes.object,
};

AppHeaderMenu.defaultProps = {
  parent: null,
};

export default AppHeaderMenu;
