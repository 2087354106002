import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
// import { JsonToTable } from 'react-json-to-table';

function capitalize(input) {
  return input.toLowerCase().split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');
}

function pathToTitle(pathname) {
  let returnValue = `${pathname}`;
  returnValue = returnValue.replace('-', ' ').replace('/', '');
  returnValue = capitalize(returnValue);
  return returnValue;
}

class Page extends Component {
  constructor(props) {
    super(props);
    this.parent = props.parent;
    this.appState = props.parent.appState;
  }

  render() {
    const { location } = window;
    let content = '';
    let title = pathToTitle(location.pathname);
    switch (location.pathname.replace('/staging', '')) {
      case '/about-us':
        content = 'A list of servers for rent.';
        break;
      case '/contact-us':
        content = 'Coming Soon..';
        break;
      case '/privacy-policy':
        content = 'Coming Soon..';
        break;
      case '/terms-of-service':
        content = 'Coming Soon..';
        break;
      case '/credits':
        content = 'Coming Soon..';
        break;
      case '/sitemap':
        content = 'Coming Soon..';
        break;
      default:
        title = pathToTitle(location.pathname);
        content = '404';
        break;
    }
    return (
      <div>
        <h1>
          {`${title}`}
        </h1>
        <br />
        {`${content}`}
        <br />
        {/* {`Path is: ${location.pathname}`} */}
      </div>
    );
  }
}

Page.propTypes = {
  // parent: PropTypes.element,
  parent: PropTypes.object,
};

Page.defaultProps = {
  parent: null,
};

export default Page;
