import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { GMap } from 'primereact/gmap';
import Settings from '../../../settings/settings';
import Query from '../../../query/query';
import OfferTemplateFunctions from '../offer/offertemplatefunctions';

class AppMap extends Component {
  constructor(props) {
    super(props);
    this.parent = props.parent;
    this.appState = this.parent.appState;

    this.otf = new OfferTemplateFunctions();
    this.settings = new Settings();
    this.query = new Query();
    // this.appState.getGoogleMapsKey();

    const mapOptions = {
      center: {
        lat: 36.890257,
        lng: 30.707417,
      },
      zoom: 3,
      type: 'terrain',
      mapTypeControl: false,
      zoomControl: false,
      streetViewControl: false,
    };

    this.state = {
      googleMapsReady: false,
      offerRows: [], // eslint-disable-line react/no-unused-state
      mapOptions,
      mapOverlays: null,
    };

    this.addMarker = this.addMarker.bind(this);

    this.onMapReady = this.onMapReady.bind(this);
    this.onMapClick = this.onMapClick.bind(this);
    this.onOverlayClick = this.onOverlayClick.bind(this);

    this.loadOMapffers = this.loadOMapffers.bind(this);
  }

  componentDidMount() {
    /*
    let {
      googleMapsReady,
    } = this.state;

    const d = document;
    const s = d.createElement('script');
    s.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDYwN1twEZBqDjg7lNdO2ebwd3_nFSIbkA&callback=initMap';
    s.setAttribute('async', '');
    s.setAttribute('defer', '');
    (d.head || d.body).appendChild(s);

    googleMapsReady = true;
    this.setState({
      googleMapsReady,
    });
    */
    this.loadOMapffers();
  }

  onMapReady(event) { // eslint-disable-line no-unused-vars, class-methods-use-this
  }

  onMapClick(event) { // eslint-disable-line class-methods-use-this, no-unused-vars
  }

  onOverlayClick(event) {
    const isMarker = event.overlay.getTitle !== undefined;

    if (isMarker) {
      const title = event.overlay.getTitle();
      this.infoWindow = this.infoWindow
        || new google.maps.InfoWindow(); // eslint-disable-line no-undef
      this.infoWindow.setContent(`<div> ${title} </div>`);
      this.infoWindow.open(event.map, event.overlay);
      event.map.setCenter(event.overlay.getPosition());
    }
  }

  addMarker({ markerTitle, lat, lng }) {
    const {
      mapOverlays,
    } = this.state;

    const newMarker = new google.maps.Marker({ // eslint-disable-line no-undef
      position: {
        lat: parseFloat(lat),
        lng: parseFloat(lng),
      },
      title: markerTitle,
      draggable: false,
    });

    if (mapOverlays !== null) {
      this.setState({
        mapOverlays: [
          ...mapOverlays,
          newMarker,
        ],
      });
    } else {
      this.setState({
        mapOverlays: [
          newMarker,
        ],
      });
    }
  }

  loadOMapffers() {
    const returnValue = new Promise((resolve, reject) => {
      const {
        endPoint,
        body,
      } = this.query.getOfferMapEndPoint();
      console.log(`body: ${JSON.stringify(body)}`);
      fetch(endPoint,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `client ${this.appState.getToken()}`,
          },
          body,
        })
        .then((response) => {
          if (response.ok) {
            // console.log('response.ok');
            response.json().then(
              (json) => {
                if (json.data) {
                  if (json.data.offers) {
                    const offerRows = json.data.offers;
                    offerRows.forEach((row) => {
                      const yearlyCost = (row.yearly_cost > 0) ? `${this.otf.formatCost(row.yearly_cost)} /y <br/>` : '';
                      const monthlyCost = (row.monthly_cost > 0) ? `${this.otf.formatCost(row.monthly_cost)} /m <br/>` : '';
                      this.addMarker({
                        markerTitle: `<a href="${this.appState.getBaseUrl()}o/${row.shortcode}">
                                         ${row.name}
                                         <br />
                                         <br />
                                         ${yearlyCost}
                                         ${monthlyCost}                                       
                                       </a>
                                       <br />`,
                        lat: row.latitude,
                        lng: row.longitude,
                      });
                    });

                    this.setState({
                      offerRows, // eslint-disable-line react/no-unused-state
                      googleMapsReady: true,
                    });

                    resolve(offerRows);
                  } else {
                    resolve(null);
                  }
                } else {
                  resolve(null);
                }
              },
            );
          } else {
            // reject('not response.ok'); // eslint-disable-line prefer-promise-reject-errors
            // reject('not response.ok'); // eslint-disable-line prefer-promise-reject-errors
            // alert(JSON.stringify(response));
            reject(JSON.stringify(response));
          }
        });
    }).catch(
      // (error) => { throw new Error(error);
      (error) => {
        console.log(`Error: ${error}`);
      },
    );
    return returnValue;
  }

  render() {
    const {
      googleMapsReady,
      mapOptions,
      mapOverlays,
    } = this.state;

    return (
      <>
        {(googleMapsReady)
          ? (
            <>
              <h1>Map</h1>
              <br />
              Click on a marker to find an offer.
              <br />
              <br />
              <GMap
                options={mapOptions}
                overlays={mapOverlays}
                onMapReady={this.onMapReady}
                onMapClick={this.onMapClick}
                onOverlayClick={this.onOverlayClick}
                style={{ width: '100%', minHeight: '80vh' }}
              />
            </>
          ) : (
            <>
            </>
          )}
        <br />
      </>
    );
  }
}

AppMap.propTypes = {
  // parent: PropTypes.element,
  parent: PropTypes.object,
};

AppMap.defaultProps = {
  parent: null,
};

export default AppMap;
