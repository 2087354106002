import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Accordion, AccordionTab } from 'primereact/accordion';
// import 'primereact/resources/themes/nova/theme.css';
// import 'primereact/resources/primereact.min.css';
// import 'primeicons/primeicons.css';

import AppAdminManageUsers from './appadminmanageusers';
import AppAdminManageCompanies from './appadminmanagecompanies';
import AppAdminManageOffers from './appadminmanageoffers';

import CommonTemplateFunctions from '../common/commontemplatefunctions';

class AppAdmin extends Component {
  constructor(props) {
    super(props);
    this.parent = props.parent.parent;
    this.appState = this.parent.appState;

    this.commontf = new CommonTemplateFunctions();

    this.state = {
    };
  }

  render() {
    const {
      role,
    } = this.appState;

    return (
      <>
        {`You are ${this.commontf.formatField(role)}`}
        <br />
        <br />
        <Accordion style={{ overflowX: 'scroll' }}>
          <AccordionTab header="Manage Users">
            <>
              <AppAdminManageUsers parent={this} />
            </>
          </AccordionTab>
          <AccordionTab header="Manage Companies">
            <>
              <AppAdminManageCompanies parent={this} />
            </>
          </AccordionTab>
          <AccordionTab header="Manage Offers">
            <>
              <AppAdminManageOffers parent={this} />
            </>
          </AccordionTab>
        </Accordion>
        <br />
        <br />
      </>
    );
  }
}

AppAdmin.propTypes = {
  // parent: PropTypes.element,
  parent: PropTypes.object,
};

AppAdmin.defaultProps = {
  parent: null,
};

export default AppAdmin;
