import Settings from '../settings/settings';
import Utility from '../utility/utility';

// eslint-disable-line 
class Locale {
  constructor() {
    this.settings = new Settings();
    this.utility = new Utility();

    this.locale = 'en-US';
    this.t = this.t.bind(this);
  }

  t(string) {
    let localeString;
    switch (this.locale) {
      case 'en-US':
        localeString = string;
        break;
      default:
        localeString = string;
    }
    return localeString;
  }
}

export default Locale;
