import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import Settings from '../../../settings/settings';

class AppBody extends Component {
  constructor(props) {
    super(props);

    this.parent = props.parent;
    this.appState = props.parent.appState;
    this.settings = new Settings();
  }

  render() {
    return (
      <div className="appBody">
        <Router>
          <div>
            <Switch>
              {/*
                exact will match /home
                without exact it will match all/home
              */}
              <Route exact path="/" component={this.parent.Home} />
              <Route exact path="/staging/" component={this.parent.Home} />

              <Route exact path="/home" component={this.parent.Home} />
              <Route exact path="/staging/home" component={this.parent.Home} />

              <Route exact path="/o" component={this.parent.Home} />
              <Route exact path="/staging/o" component={this.parent.Home} />

              <Route exact path="/login" component={this.parent.Login} />
              <Route exact path="/staging/login" component={this.parent.Login} />

              <Route exact path="/logout" component={this.parent.LogOutPage} />
              <Route exact path="/staging/logout" component={this.parent.LogOutPage} />

              <Route exact path="/register" component={this.parent.Register} />
              <Route exact path="/staging/register" component={this.parent.Register} />

              <Route exact path="/forgot-password" component={this.parent.ForgotPassword} />
              <Route exact path="/staging/forgot-password" component={this.parent.ForgotPassword} />

              <Route exact path="/dashboard" component={this.parent.Dashboard} />
              <Route exact path="/staging/dashboard" component={this.parent.Dashboard} />

              <Route exact path="/map" component={this.parent.MapPage} />
              <Route exact path="/staging/map" component={this.parent.MapPage} />

              <Route exact path="/providers" component={this.parent.CompanyPage} />
              <Route exact path="/staging/providers" component={this.parent.CompanyPage} />
              <Route exact path="/p" component={this.parent.CompanyPage} />
              <Route exact path="/staging/p" component={this.parent.CompanyPage} />

              <Route exact path="/p/:companyId" component={this.parent.OffersFromCompany} />
              <Route exact path="/staging/p/:companyId" component={this.parent.OffersFromCompany} />

              <Route exact path="/companies" component={this.parent.CompanyPage} />
              <Route exact path="/staging/companies" component={this.parent.CompanyPage} />

              <Route exact path="/about-us" component={this.parent.Page} />
              <Route exact path="/staging/about-us" component={this.parent.Page} />

              <Route exact path="/contact-us" component={this.parent.Page} />
              <Route exact path="/staging/contact-us" component={this.parent.Page} />

              <Route exact path="/o/:shortcode" component={this.parent.OfferDetailPage} />
              <Route exact path="/staging/o/:shortcode" component={this.parent.OfferDetailPage} />
            </Switch>
          </div>
        </Router>
      </div>
    );
  }
}

AppBody.propTypes = {
  // parent: PropTypes.element,
  parent: PropTypes.object,
};

AppBody.defaultProps = {
  parent: null,
};

export default AppBody;
